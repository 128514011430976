import { useEffect } from 'react';
            import { useLocation } from 'react-router-dom';
            import { Box, Button, Typography, useTheme } from '@mui/material';
            import { Link as RouterLink } from 'react-router-dom';

            const UnauthorizedPage = () => {
                const theme = useTheme();
                const isDark = theme.palette.mode === 'dark';
                const location = useLocation();

                const orgId = localStorage.getItem('orgId')?.replace(/"/g, '');

                useEffect(() => {
                    if (location.pathname === '/') {
                        window.location.reload();
                    }
                }, [location]);

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '100vh',
                            background: isDark
                                ? 'linear-gradient(195deg, #111827, #1F2937)'
                                : 'linear-gradient(195deg, #F8FAFC, #F1F5F9)',
                            p: 4,
                            textAlign: 'center'
                        }}
                    >
                        <Box
                            component="svg"
                            viewBox="0 0 24 24"
                            sx={{
                                width: 120,
                                height: 120,
                                mb: 4,
                                color: theme.palette.error.main,
                                filter: isDark ? 'drop-shadow(0 0 8px rgba(239, 68, 68, 0.3))' : null
                            }}
                        >
                            <path
                                fill="currentColor"
                                d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z"
                            />
                            <path
                                fill="currentColor"
                            />
                        </Box>

                        <Typography
                            variant="h1"
                            sx={{
                                fontSize: '2.5rem',
                                fontWeight: 700,
                                mb: 2,
                                color: theme.palette.text.primary,
                                letterSpacing: '-0.025em'
                            }}
                        >
                            Access Restricted
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                color: theme.palette.text.secondary,
                                maxWidth: '480px',
                                mb: 4,
                                fontSize: '1.125rem',
                                lineHeight: 1.5
                            }}
                        >
                            You do not have sufficient permissions to access this resource. Please verify your account privileges or contact your organization administrator.
                        </Typography>

                        <Button
                            component={RouterLink}
                            to={`/organisation/${orgId}/category/GENERAL/thread/1`}
                            variant="contained"
                            size="large"
                            sx={{
                                px: 4,
                                py: 1.5,
                                borderRadius: '8px',
                                textTransform: 'none',
                                fontSize: '1rem',
                                fontWeight: 600,
                                background: theme.palette.mode === 'dark'
                                    ? 'linear-gradient(45deg, #6366F1, #4F46E5)'
                                    : 'linear-gradient(45deg, #4F46E5, #6366F1)',
                                '&:hover': {
                                    transform: 'translateY(-1px)',
                                    boxShadow: theme.shadows[3]
                                },
                                transition: 'all 0.2s ease'
                            }}
                        >
                            Return to Home
                        </Button>
                    </Box>
                );
            };

            export default UnauthorizedPage;