import * as React from 'react';
import { Routes, Route } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import Layout from "./layouts/Layout";
import HomePage from "./pages/HomePage";
import ChatCategoryPage from "./pages/ChatCategoryPage";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import PasswordResetPage from "./pages/PasswordRestePage";
import PaymentStatusPage from "./pages/PaymentStatusPage";
import AuthenticationStatusPage from "./pages/AuthenticationStatusPage";
import DatabasesPage from "./pages/DatabasesPage";
import LandingPage from "./pages/LandingPage";
import FilesPage from "./pages/FilesPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import ToolsPage from "./pages/ToolsPage";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useTheme, ThemeProvider, createTheme, Theme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import OrganisationSettings from './components/organisation/OrganisationSettings';
import { useParams } from "react-router-dom";
import OrganisationUsers from './components/organisation/OrganisationUsers';
import BillingComponent from './components/organisation/BillingComponent';
import ProtectedRoute from './guards/ProtectedRoute';
import UnauthorizedPage from "./pages/Unauthorized";
declare module '@mui/material/styles' {
  interface Palette {
    gradients: {
      primary: string;
      primaryHover: string;
      primaryActive: string;
    };
  }
  interface PaletteOptions {
    gradients?: {
      primary?: string;
      primaryHover?: string;
      primaryActive?: string;
    };
  }
}

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export function DarkModeToggle() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <Box>
      <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  );
}

function App() {
  const [mode, setMode] = React.useState<'light' | 'dark'>(() => {
    const savedMode = localStorage.getItem('themeMode');
    return savedMode ? (savedMode as 'light' | 'dark') : 'light';
  });
  const { orgId } = useParams();

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('themeMode', newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#805EE9',
            light: mode === 'light' ? '#B094FF' : '#6C39D4',
            dark: mode === 'light' ? '#6C39D4' : '#4F25A8',
            contrastText: '#FFFFFF',
          },
          secondary: {
            main: '#4486F6',
            light: mode === 'light' ? '#72B0FA' : '#2E6AE0',
            dark: mode === 'light' ? '#2A5AC0' : '#1E47A0',
            contrastText: '#FFFFFF',
          },
          background: {
            default: mode === 'light' ? '#F5F6FA' : '#161B26',
            paper: mode === 'light' ? '#FFFFFF' : '#202836',
          },
          text: {
            primary: mode === 'light' ? '#161B26' : '#F5F6FA',
            secondary: mode === 'light' ? '#4A5562' : '#A7AEB9',
            disabled: mode === 'light' ? '#CACFD5' : '#4A5562',
          },
          divider: mode === 'light' ? '#DFE1E5' : '#383B42',
          gradients: {
            primary: 'linear-gradient(45deg, #805EE9 30%, #4486F6 90%)',
            primaryHover: 'linear-gradient(45deg, #6C39D4 30%, #2E6AE0 90%)',
            primaryActive: 'linear-gradient(45deg, #4F25A8 30%, #1E47A0 90%)',
          },
        },
        typography: {
          fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'sans-serif',
          ].join(','),
          h1: {
            fontWeight: 700,
            fontSize: '2.25rem',
            color: mode === 'light' ? '#202836' : '#F5F6FA',
          },
          body1: {
            lineHeight: 1.6,
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: '8px',
                textTransform: 'none',
                background: 'linear-gradient(45deg, #805EE9 30%, #4486F6 90%)',
                color: '#FFFFFF',
                '&:hover': {
                  background: 'linear-gradient(45deg, #B094FF 30%, #72B0FA 90%)',
                },
                '&:active': {
                  background: 'linear-gradient(45deg, #6C39D4 30%, #2E6AE0 90%)',
                },
              },
            },
          },
         MuiTextField: {
            styleOverrides: {
              root: {
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: mode === 'light' ? '#A2A6FF' : '#484C63',
                    borderWidth: '2px',
                  },
                  '&:hover fieldset': {
                    borderColor: mode === 'light' ? '#8E92F2' : '#63677F',
                    borderWidth: '2px',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#7F3FFF',
                    borderWidth: '2px',
                  },
                },
              },
            },
          },
        },
        shape: {
          borderRadius: 8,
        },
        shadows: [
          'none',
          mode === 'light'
            ? '0 1px 3px rgba(0, 0, 0, 0.05)'
            : '0 1px 3px rgba(255, 255, 255, 0.05)',
          ...Array(23).fill(
            mode === 'light'
              ? '0 1px 3px rgba(0, 0, 0, 0.1)'
              : '0 1px 3px rgba(255, 255, 255, 0.1)'
          ),
        ] as Theme['shadows'],
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/auth/" element={<AuthLayout />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="reset-password" element={<PasswordResetPage />} />
          </Route>

          <Route path="/" element={<Layout />}>
            <Route
                path="/organisation/:orgId/category/:categoryIdParam/thread/:threadIdParam"
                element={
                    <HomePage />
                }
            />
            <Route path="verify-email" element={<VerifyEmailPage />} />
          </Route>

          <Route path="/organisation/:orgId" element={<Layout />}>
            <Route
                path="settings"
                element={
                  <ProtectedRoute requiredPermissions={['update_org_settings']}>
                    <OrganisationSettings />
                  </ProtectedRoute>
                }
            />
            <Route
                path="users"
                element={
                  <ProtectedRoute requiredPermissions={['view_edit_member_list']}>
                    <OrganisationUsers />
                  </ProtectedRoute>
                }
            />
            <Route
                path="billing"
                element={
                  <ProtectedRoute requiredPermissions={['view_billing', 'edit_purchase_tokens']} checkType="any">
                    <BillingComponent />
                  </ProtectedRoute>
                }
            />
            <Route
                path="chat-category"
                element={
                  <ProtectedRoute requiredPermissions={['view_chat_topics']}>
                    <ChatCategoryPage />
                  </ProtectedRoute>
                }
            />
            <Route
                path="databases"
                element={
                  <ProtectedRoute requiredPermissions={['view_drives']}>
                    <DatabasesPage />
                  </ProtectedRoute>
                }
            />
            <Route
                path="files"
                element={
                  <ProtectedRoute requiredPermissions={['view_media']}>
                    <FilesPage />
                  </ProtectedRoute>
                }
            />
            <Route
                path="tools"
                element={
                  <ProtectedRoute requiredPermissions={['view_chat_topics']}>
                    <ToolsPage />
                  </ProtectedRoute>
                }
            />
          </Route>

          {/* Public routes */}
          <Route path="payment/:status" element={<PaymentStatusPage />} />
          <Route path="authentication/:status" element={<AuthenticationStatusPage />} />
          <Route path="home" element={<LandingPage />} />
          <Route path="unauthorized" element={<UnauthorizedPage />} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
