
export const appReducer = (state:any, action:any) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: action.payload };

    case "SHOW_CONFIRMATION":
      return { ...state , conformation: action.payload };
    case "SHOW_MODAL":
    return { ...state , model: action.payload };
    case "DRAWER":
      return { ...state, drawer: action.payload };
    case "SET_NOTIFICATION":
      return { ...state, notification: action.payload };
    case "SET_MESSAGE":
      return { ...state, message: action.payload };    
    case "SET_THREAD_ID":
      return { ...state, threadId: action.payload };
    case "SET_GENERAL_THREADS":
      return { ...state, generalThreads: action.payload };
    case "SET_USER_PERMISSIONS":
        return { ...state, userPermissions: action.payload };
    case "SET_SHOW_FAQ":
      return { ...state , showFaq: action.payload };
    case "SET_FAQ":
      return { ...state , selectedFaq: action.payload, showFaq: false };
    case "SET_SHOW_SPENT_MATRIX":
      return { ...state , spentMatrix: action.payload };

    case "SET_MATRIX":
      return { ...state , matrixData: action.payload };

    case "SET_USER":
      return { ...state , user: action.payload };

    case "SET_ACTIVE_ORGANISATION":
      window.localStorage.setItem('orgId', JSON.stringify(action.payload?._id));
      return { ...state , selectedOrganisation: action.payload };
    case "SET_ORGANISATIONS":
      return { ...state , organisations: action.payload };
    case "SET_ORGANISATION":
      return { ...state , organisation: action.payload };
    case "IS_ORGANISATION_NOTEXIST":
      return { ...state , isOrganisationNotExist: action.payload };
    case 'SET_PENDING_REQUESTS':
      return { ...state , pendingRequests: action.payload };
    case "SET_CHAT_CATEGORIES":
      return { ...state , chatCategories: action.payload };
    case "SET_DATABASES":
      return { ...state , databases: action.payload };
    case "SET_FILES":
      return { ...state , files: action.payload };
    case "SET_USER_CHAT_CATEGORIES":
      return { ...state , userChatCategories: action.payload };
    case "SET_USER_ACTIVE_CHAT_CATEGORY":
      return { ...state , selectedChatCategory: action.payload };
    case "SELECT_THREAD":
      return { ...state , selectedThread: action.payload };
    case "SET_ZAPIER_ACTIONS":
      return { ...state , zapierActions: action.payload };
    case "SET_SELECTED_MODEL":
      return { ...state, selectedModel: action.payload };
    case "SET_IS_COLLABORATIVE_THREAD":
      return { ...state, isCollaborativeThread: action.payload };
    case "SET_K_VALUE":
      return { ...state, kValue: action.payload };
    default:
      throw new Error('Invalid Action Type!');
  }
};