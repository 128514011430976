import React, { useEffect, useState } from "react";
import { getIcon } from "../../components/utils";
import { useApp, useAuth } from "../../hooks";
import SettingsPage from "../../pages/SettingsPage";
import Avatar from "@mui/material/Avatar";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DarkModeToggle } from "../../App";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import { useNavigate, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemIcon from '@mui/material/ListItemIcon';
import EmailIcon from '@mui/icons-material/Email';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import logo_white from '../../assets/logos/logo_white.png'
import {Typography} from "@mui/material";
import {FaQuestionCircle} from "react-icons/fa";

import {Driver, driver} from 'driver.js';
import 'driver.js/dist/driver.css';
import './Popover.css';
import {AccountMenu} from "../../components/chat/AccountMenu";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Header = ({ children, outlet }: any) => {
  const { state, dispatch }: any = useApp();
  const navigate = useNavigate();
  const [activeTabs, setActiveTabs] = useState<{ [key: string]: number }>({users: 0, databases: 0, categories: 0 });
  const [skipAutoAdvance, setSkipAutoAdvance] = useState(false);
  const [isTourInProgress, setIsTourInProgress] = useState(false);
  const [tourDirection, setTourDirection] = useState<'next' | 'prev' | null>(null);
  const { auth, setAuth }: any = useAuth();
  const [profilePhoto, setProfilePhoto] = useState('');
  const { selectedOrganisation } = state;
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const { threadIdParam, categoryIdParam, orgId }: any = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const orgOpenMenu = Boolean(anchorEl);
  const handleOrgMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOrgMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleExternalTabChange = (event: Event, section: string) => {
      const customEvent = event as CustomEvent<number>;
      setActiveTabs((prev) => ({ ...prev, [section]: customEvent.detail }));
    };

    window.addEventListener('switchTab-users', (event) => handleExternalTabChange(event, 'users'));
    window.addEventListener('switchTab-databases', (event) => handleExternalTabChange(event, 'databases'));
    window.addEventListener('switchTab-categories', (event) => handleExternalTabChange(event, 'chat-category'));

    return () => {
      window.removeEventListener('switchTab-users', (event) => handleExternalTabChange(event, 'users'));
      window.removeEventListener('switchTab-databases', (event) => handleExternalTabChange(event, 'databases'));
      window.removeEventListener('switchTab-categories', (event) => handleExternalTabChange(event, 'chat-category'));
    };
  }, []);

  const switchTabExternally = (tabIndex: number) => {
    if (window.location.pathname.includes('/users')) {
      window.dispatchEvent(new CustomEvent('switchTab-users', { detail: tabIndex }));
    } else if (window.location.pathname.includes('/databases')) {
      window.dispatchEvent(new CustomEvent('switchTab-databases', { detail: tabIndex }));
    } else if (window.location.pathname.includes('/chat-category')) {
      window.dispatchEvent(new CustomEvent('switchTab-categories', { detail: tabIndex }));
    }
  };

  const GradientMenu = styled(Menu)({
    '& .MuiPaper-root': {
      background: 'linear-gradient(to bottom right, #C0B7EA, #B4C5F2)',
      borderRadius: '5px',
      width: '220px'
    },
  });

  const GradientMenuItem = styled(MenuItem)({
    backgroundColor: theme.palette.background.paper,
    margin: '4px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  });

  let driverObj!: Driver;

  // BILLING TOUR
  //////////////////////////////////////////////////
  if (window.location.pathname.includes('/billing')) {
    driverObj = driver({
      popoverClass: 'driverjs-theme',
      showProgress: true,
      steps: [
        {
          element: '#tour',
          popover: {
            title: 'Welcome to Billing',
            description: 'Let’s explore how billing works in DatalinkerAI!',
          },
        },
        {
          element: '#edit-billing-button',
          popover: {
            title: 'Manage Your Billing',
            description: 'Click this button to access the Stripe website, where you can view your billing details, update your plan, and manage payment information.',
          },
        },
        {
          element: '#plan-name',
          popover: {
            title: 'Your Subscription Plan',
            description: 'This section displays the plan you’re currently subscribed to. To make changes, click the "Edit Billing" button above.',
          },
        },
        {
          element: '#subscription-status',
          popover: {
            title: 'Subscription Status',
            description: 'Here, you’ll see if your subscription is active. If it’s inactive, there may be an issue with your payment. Click "Edit Billing" to check and resolve any issues.',
          },
        },
        {
          element: '#payment-status',
          popover: {
            title: 'Payment Status',
            description: 'This shows whether your payments are up to date. If your payment is overdue, your subscription may be affected.',
          },
        },
        {
          element: '#billing-date',
          popover: {
            title: 'Next Billing Date',
            description: 'This indicates the date of your next scheduled payment to keep your subscription active.',
          },
        },
        {
          element: '#resource-table',
          popover: {
            title: 'Resource Usage',
            description: 'Here, you can see how many tokens you have left for each LLM. Tokens are tracked separately for input and output usage.',
          },
        },
        {
          element: '#llm-box',
          popover: {
            title: 'Token Balance',
            description: 'These boxes show the number of tokens available for each LLM, along with the amount already used.',
          },
        },
        {
          element: '#token-add-button',
          popover: {
            title: 'Purchase More Tokens',
            description: 'Click "Add" to purchase additional tokens for an LLM.',
          },
        },
        {
          element: '#tour',
          popover: {
            title: 'Billing Tour Complete',
            description: 'You’ve completed the Billing Tour! Check out other guides to learn more about DatalinkerAI.',
          },
        },
      ],
    });
  }

  // USERS TOUR
  //////////////////////////////////////////////////
  if (window.location.pathname.includes('/users')) {
    driverObj = driver({
      popoverClass: 'driverjs-theme',
      animate: true,
      showProgress: true,
      steps: [
        {
          element: '#tour',
          popover: {
            title: 'Welcome to the Users Tour',
            description: 'Let’s explore how users work within your organization!',
          },
        },
        {
          element: '.ag-center-cols-container',
          popover: {
            title: 'Members Table',
            description: 'This table displays all members in your organization.',
            side: "top",
          },
        },
        {
          element: '.ag-root .ag-row:first-child .ag-cell:nth-child(2)',
          popover: {
            title: 'User Names',
            description: 'Here, you’ll find the names of all users in the organization.',
          },
        },
        {
          element: '.ag-root .ag-row:first-child .ag-cell:nth-child(3)',
          popover: {
            title: 'User Emails',
            description: 'This column displays the email addresses of organization members.',
          },
        },
        {
          element: '.ag-root .ag-row:first-child .ag-cell:nth-child(4)',
          popover: {
            title: 'Roles',
            description: 'Each user has a role assigned to them. This column shows their current role.',
          },
        },
        {
          element: '.ag-root .ag-row:first-child .ag-cell:nth-child(5)',
          popover: {
            title: 'User Actions',
            description: 'Here, you can edit a user’s role or remove them from the organization.',
          },
        },
        {
          element: '#tour',
          popover: {
            title: 'Next Steps',
            description: 'Now that we’ve covered users and roles, let’s see how to add new users!',
          },
        },
        {
          element: '#invite-users',
          popover: {
            title: 'Inviting Users',
            description: 'Clicking this button copies an invite link to your clipboard. Share it with a user so they can request to join your organization. Note: Users must have a DatalinkerAI account to send a join request.',
            onNextClick: () => {
              setSkipAutoAdvance(true);
              switchTabExternally(1);

              setTimeout(() => {
                setSkipAutoAdvance(false);
                setTourDirection('next');
                setIsTourInProgress(true);
              }, 400);
            },
          },
        },
        {
          element: '.ag-center-cols-viewport',
          popover: {
            title: 'Join Requests',
            description: 'All pending join requests will appear here, along with the user’s name and email. You can accept or deny each request.',
            onNextClick: () => {
              setSkipAutoAdvance(true);
              switchTabExternally(2);

              setTimeout(() => {
                setSkipAutoAdvance(false);
                setTourDirection('next');
                setIsTourInProgress(true);
              }, 400);
            },
            onPrevClick: () => {
              switchTabExternally(1);
              setTimeout(() => {
                driverObj.movePrevious();
              }, 100);
            }
          },
        },
        {
          element: '.ag-center-cols-container',
          popover: {
            title: 'Roles in the Organization',
            description: 'Your organization can have users with different roles and permissions.',
            onPrevClick: () => {
              switchTabExternally(1);
              setTimeout(() => {
                setTourDirection('prev');
                setIsTourInProgress(true);
              }, 100);
            }
          },
        },
        {
          element: '.ag-center-cols-container .ag-cell[col-id="role_name"]',
          popover: {
            title: 'Role Name',
            description: 'This shows the name of each assigned role.',
          },
        },
        {
          element: '.ag-center-cols-container .ag-cell[col-id="role_description"]',
          popover: {
            title: 'Role Description',
            description: 'A brief description of the role’s purpose and responsibilities.',
          },
        },
        {
          element: '.ag-center-cols-container .ag-cell[aria-colindex="3"]',
          popover: {
            title: 'Role Actions',
            description: 'Manage roles by editing or making adjustments.',
          },
        },
        {
          element: '.ag-center-cols-container .ag-cell[aria-colindex="3"] button[aria-label="Edit Role"]',
          popover: {
            title: 'Creating Roles',
            description: 'Click this button to create a new user’s role.',
            onNextClick: () => {
              const button = document.getElementById('create-role-button') as HTMLButtonElement;
              // const button = document.querySelector('.ag-center-cols-container .ag-cell[aria-colindex="3"] button[aria-label="Edit Role"]') as HTMLButtonElement;
              if (button) button.click();
              setTourDirection('next');
              setIsTourInProgress(true);
            }
          },
        },
        {
          element: '#roles-modal',
          popover: {
            title: 'Role Management',
            description: 'Create or customize roles with specific permissions for users.',
          },
        },
        {
          element: '#role-name-box',
          popover: {
            title: 'Role Name',
            description: 'Set or modify the name of the role you’re creating or updating.',
          },
        },
        {
          element: '#role-description-box',
          popover: {
            title: 'Role Description',
            description: 'Provide a description for the role to clarify its purpose.',
          },
        },
        {
          element: '#chat-topic-permissions',
          popover: {
            title: 'Setting Permissions',
            description: "Assign permissions to roles by selecting the features they can access. Some permissions require others to be enabled first.",
          },
        },
        {
          element: '#chat-topic-permissions label:nth-last-of-type(2)',
          popover: {
            title: 'Automatic Permissions',
            description: "For example, enabling 'Share chat topics with other users' will automatically enable required permissions.",
            onNextClick: () => {
              const label = document.querySelector("#chat-topic-permissions label:nth-last-of-type(2)");
              if (label) {
                const checkbox = label.querySelector(".MuiCheckbox-root input[type='checkbox']") as HTMLInputElement;
                if (checkbox && !checkbox.checked) checkbox.click();
              }
              setTourDirection('next');
              setIsTourInProgress(true);
            }
          },
        },
        {
          element: '#chat-topic-permissions',
          popover: {
            title: 'Linked Permissions',
            description: "'Access to Chat topic listings' is enabled automatically when related permissions are selected. Other areas, like Drives and Media, function the same way.",
            onNextClick: () => {
              setTourDirection('next');
              setIsTourInProgress(true);
            }
          },
        },
        {
          element: '#tour',
          popover: {
            title: 'Users Tour Complete',
            description: "You’ve completed the Users Tour! Check out other tours to learn more about DatalinkerAI.",
            onNextClick: () => {
              const cancelButton = document.querySelector('#cancel-button') as HTMLButtonElement;
              if (cancelButton) {cancelButton.click()}
              setTourDirection('next');
              setIsTourInProgress(true);
            }
          },
        },
      ],
    });

    // DRIVES TOUR
    //////////////////////////////////////////////////
  } else if (window.location.pathname.includes('/databases')) {
    driverObj = driver({
      popoverClass: 'driverjs-theme',
      showProgress: true,
      steps: [
        {
          element: '#tour-example',
          popover: {
            title: 'Welcome to Drives',
            description: 'Drives are the foundation of your organization’s data. Let’s explore how they work!',
          },
        },
        {
          element: '#drives-list',
          popover: {
            title: 'Understanding Drives',
            description: 'Think of Drives as your organization’s data repositories. They store documents, videos, and other content, forming the backbone of "Chat Topics." This table provides key details about each Drive.',
          },
        },
        {
          element: '.ag-header-cell-label',
          popover: {
            title: 'Customizing Your View',
            description: 'Click on column headers to sort data in ascending or descending order. You can also rearrange columns by dragging them.',
          },
        },
        {
          element: '.ag-floating-filter-body',
          popover: {
            title: 'Quick Search',
            description: 'Use this search bar to quickly find a specific Drive by name.',
          },
        },
        {
          element: '.ag-floating-filter-button',
          popover: {
            title: 'Advanced Filtering',
            description: 'Click this button to refine your search with advanced filtering options.',
          },
        },
        {
          element: '.ag-date-filter',
          popover: {
            title: 'Filter by Date',
            description: 'Sort and filter Drives based on their creation or last updated date.',
          },
        },
        {
          element: '.ag-row:first-child .ag-cell.ag-column-last',
          popover: {
            title: 'Drive Actions',
            description: 'Use these buttons to sync, edit, or delete a Drive when needed.',
          },
        },
        {
          element: '#tour-example',
          popover: {
            title: 'Creating a New Drive',
            description: 'Now that you understand how Drives work, let’s create one!',
            onNextClick: () => {
              setSkipAutoAdvance(true);
              switchTabExternally(1);
              setTimeout(() => {
                setSkipAutoAdvance(false);
                setTourDirection('next');
                setIsTourInProgress(true);
              }, 400);
            }
          },
        },
        {
          element: '#create-drive-table',
          popover: {
            title: 'New Drive Setup',
            description: 'This section is where you create new Drives for your organization.',
            onPrevClick: () => {
              switchTabExternally(0);
              setTourDirection('prev');
              setIsTourInProgress(true);
            },
          },
        },
        {
          element: '.MuiInputBase-input',
          popover: {
            title: 'Naming Your Drive',
            description: 'Enter a name for your Drive. Avoid using special characters such as *&@#.',
          },
        },
        {
          element: '.MuiGrid-container > :first-child',
          popover: {
            title: 'Upload Files Locally',
            description: 'Choose and upload files from your computer, just like you would on any other platform.',
          },
        },
        {
          element: '.MuiGrid-container > :last-child',
          popover: {
            title: 'Import from Google Drive',
            description: 'You can also import files directly from Google Drive. Ensure your Google account is linked first.',
          },
        },
        {
          element: '#create-drive-table > .div:first-child > div:last-child > button',
          popover: {
            title: 'Save Your Drive',
            description: 'Once you’ve named your Drive and added files, click "Save" to finalize and add it to your Drives List.',
          },
        },
        {
          element: '#tour-example',
          popover: {
            title: 'Drives Tour Complete',
            description: 'Great job! Your Drive is set up and ready to use. Explore other guides to learn more about DatalinkerAI!',
          },
        },
      ],
    });

  // MEDIA TOUR
  //////////////////////////////////////////////////
  } else if (window.location.pathname.includes('/files')) {
    driverObj = driver({
      showProgress: true,
      popoverClass: 'driverjs-theme',
      steps: [
        {
          element: '#tour',
          popover: {
            title: 'Welcome to Media',
            description: 'Let’s explore what Media is and how you can manage it effectively!',
          },
        },
        {
          element: '#media-table',
          popover: {
            title: 'Understanding the Media Table',
            description: 'This table stores the files generated when using the text-to-speech feature in a chat. These files are played back and saved here for easy access.',
          },
        },
        {
          element: '.ag-cell-label-container',
          popover: {
            title: 'Customizing Your View',
            description: 'Click on column headers to sort data in ascending or descending order. You can also rearrange columns by dragging them.',
          },
        },
        {
          element: '.ag-header-cell[col-id="file_size_mb"] .ag-cell-label-container',
          popover: {
            title: 'File Size Information',
            description: 'This column displays the size of each media file, helping you monitor storage usage.',
          },
        },
        {
          element: '.ag-header-cell[col-id="actions"] .ag-cell-label-container',
          popover: {
            title: 'Media Actions',
            description: 'Use these buttons to play, download, or delete media files as needed.',
          },
        },
        {
          element: '#tour',
          popover: {
            title: 'Media Tour Complete',
            description: 'That’s it! You now understand how to manage media files. Explore other guides to learn more about DatalinkerAI!',
          },
        },
      ],
    });
    // CHAT TOPICS TOUR
    //////////////////////////////////////////////////
  } else if (window.location.pathname.includes('/chat-category')) {
    driverObj = driver({
      popoverClass: 'driverjs-theme',
      animate: true,
      showProgress: true,
      steps: [
        {
          element: '#tour',
          popover: {
            title: 'Welcome to Chat Topics',
            description: 'Let’s explore how Chat Topics work and how they integrate with your organization!',
          },
        },
        {
          element: '#chat-topic-table',
          popover: {
            title: 'Chat Topics Overview',
            description: 'This table displays all Chat Topics created within your organization. Chat Topics work alongside Drives to enhance collaboration and data management in DatalinkerAI.',
          },
        },
        {
          element: '.ag-root .ag-row:first-child .ag-cell:nth-child(2)',
          popover: {
            title: 'Linked Drives',
            description: 'Every Chat Topic is connected to one or more Drives. The associated Drives are listed here and can be updated by editing the Chat Topic.',
          },
        },
        {
          element: '.ag-root .ag-row:first-child .ag-cell:nth-child(3)',
          popover: {
            title: 'Version Control',
            description: 'This column shows the current version of the Chat Topic. Updating the Chat Topic automatically increases the version number.',
          },
        },
        {
          element: '.ag-root .ag-row:first-child .ag-cell:nth-child(4)',
          popover: {
            title: 'Managing Chat Topics',
            description: 'Let’s take a look at the available actions for managing Chat Topics.',
          },
        },
        {
          element: '.ag-root .ag-row:first-child .ag-cell:nth-child(4) > div:first-child > *:first-child',
          popover: {
            title: 'Edit Chat Topic',
            description: 'Modify the Chat Topic’s name, instructions, and assigned Drives to keep it up to date.',
          },
        },
        {
          element: '.ag-root .ag-row:first-child .ag-cell:nth-child(4) > div:first-child > *:nth-child(2)',
          popover: {
            title: 'Delete Chat Topic',
            description: 'Permanently remove a Chat Topic from the table. Please note: This action cannot be undone.',
          },
        },
        {
          element: '.ag-root .ag-row:first-child .ag-cell:nth-child(4) > div:first-child > *:nth-child(3)',
          popover: {
            title: 'Manage Users & Roles',
            description: 'Click to view and manage users and roles assigned to this Chat Topic. You can add or remove access as needed. Let’s explore this further...',
            onNextClick: () => {
              const usersButton = document.querySelector('.ag-root .ag-row:first-child .ag-cell:nth-child(4) > div:first-child > *:nth-child(3)') as HTMLButtonElement;
              if (usersButton) {
                usersButton.click();
              }
              setTourDirection('next');
              setIsTourInProgress(true);
            }
          },
        },
        {
          element: '#assign-box',
          popover: {
            title: 'Assigning Users & Roles',
            description: 'Here, you can grant access to specific users or entire roles within your organization.',
          },
        },
        {
          element: '#function-select',
          popover: {
            title: 'Assigning Users',
            description: 'By default, this menu lets you assign individual users to a Chat Topic.',
          },
        },
        {
          element: '#name-box',
          popover: {
            title: 'Adding Users',
            description: 'Simply type a user’s name or email and click "Add User" to grant them access.',
          },
        },
        {
          element: '#function-select',
          popover: {
            title: 'Assigning Roles',
            description: 'To assign an entire role, switch this menu to "Assign Role" and proceed.',
            onNextClick: () => {
              try {
                setTimeout(() => {
                  console.log('730')
                  const selectElement = document?.querySelector("#function-select") as HTMLElement ?? null;
                  if (selectElement) {
                    console.log(selectElement);
                    selectElement.focus();
                    const keyboardEvent = new KeyboardEvent("keydown", { bubbles: true, cancelable: true, key: "ArrowDown" });
                    selectElement.dispatchEvent(keyboardEvent);
                    setTimeout(() => {
                      console.log(keyboardEvent);
                      const menuItem = document.querySelector("#role-option") as HTMLElement;
                      if (menuItem) {
                        menuItem.click();
                      }
                      setTimeout(() => {
                        console.log('moving on');
                        setTourDirection("next");
                        setIsTourInProgress(true);
                      }, 500);
                    }, 300);
                  } else {
                    driverObj.moveTo(14)
                  }
                }, 500);
              } catch (e) {
                console.error(e)
              }
            },
          },
        },
        {
          element: '#name-box',
          popover: {
            title: 'Adding Roles',
            description: 'Search for a role within your organization and assign it to this Chat Topic.',
          },
        },
        {
          element: '#table-container',
          popover: {
            title: 'Viewing Assigned Users & Roles',
            description: 'This table displays all users and roles currently assigned to the Chat Topic.',
            onNextClick: () => {
              const closeButton = document.querySelector('.MuiModal-root button[aria-label="close"]') as HTMLButtonElement;
              if (closeButton) {
                closeButton.click();
              }
              setTourDirection('next');
              setIsTourInProgress(true);
            }
          },
        },
        {
          element: '#tour',
          popover: {
            title: 'Next Steps',
            description: 'Now, let’s see how to create a new Chat Topic!',
            onNextClick: () => {
              setSkipAutoAdvance(true);
              switchTabExternally(1);
              setTimeout(() => {
                setSkipAutoAdvance(false);
                setTourDirection('next');
                setIsTourInProgress(true);
              }, 400);
            }
          },
        },
        {
          element: '.MuiBox-root form > *:first-child',
          popover: {
            title: 'Naming Your Chat Topic',
            description: 'Start by choosing a name. It can be anything, but special characters like #@!) are not allowed.',
            onPrevClick: () => {
              switchTabExternally(0);
              setTimeout(() => {
                setTourDirection('prev');
                setIsTourInProgress(true);
              }, 100);
            }
          },
        },
        {
          element: '.MuiBox-root form > *:nth-child(2)',
          popover: {
            title: 'Adding Instructions',
            description: 'Provide clear instructions for the Chat Topic to guide users.',
          },
        },
        {
          element: '.MuiBox-root form > *:nth-child(3)',
          popover: {
            title: 'Assigning Drives',
            description: 'Select up to 30 Drives to connect to this Chat Topic, functioning as its database.',
          },
        },
        {
          element: '#tour',
          popover: {
            title: 'Chat Topics Tour Complete',
            description: 'You’re all set! Explore other guides to learn more about DatalinkerAI.',
          },
        },
      ],
    });
  }

  const handleHelpClick = () => {
    const pathname = window.location.pathname;

    let activeTabId = 0; // Default

    if (pathname.includes('/users')) {
      activeTabId = activeTabs.users;
    } else if (pathname.includes('/databases')) {
      activeTabId = activeTabs.databases;
    } else if (pathname.includes('/chat-category')) {
      activeTabId = activeTabs.categories;
    }

    console.log('ActiveTAB: ', activeTabId);

    if (pathname.includes('/users')) {
      if (activeTabId === 0) {
        driverObj.drive();
      } else if (activeTabId === 1) {
        driverObj.drive(8);
      } else if (activeTabId === 2) {
        driverObj.drive(9);
      }
    } else if (pathname.includes('/databases')) {
      if (activeTabId === 0) {
        driverObj.drive();
      } else if (activeTabId === 1) {
        driverObj.drive(8);
      }
    } else if (pathname.includes('/chat-category')) {
      if (activeTabId === 0) {
        driverObj.drive();
      } else if (activeTabId === 1) {
        driverObj.drive(15);
      }
    } else if (pathname.includes('/billing')) {
      driverObj.drive();
    } else if (pathname.includes('/files')) {
      driverObj.drive();
    }
  };

  useEffect(() => {
    if (isTourInProgress) {
      setTimeout(() => {
        if (!skipAutoAdvance) {
          if (tourDirection === 'next') {
            driverObj.moveNext();
          } else if (tourDirection === 'prev') {
            driverObj.movePrevious();
          }
        }
        setIsTourInProgress(false);
      }, 500);
    }
  }, [isTourInProgress, tourDirection, driverObj, skipAutoAdvance]);


  useEffect(() => {
    if (auth?.user) {
      const image = getIcon(auth.user.email.split('')[0].toUpperCase());
      setProfilePhoto(image);
    }
  }, [auth]);

  const handleLogout = () => {
    window.localStorage.removeItem('idToken');
    window.localStorage.removeItem('refreshToken');
    setAuth({});
    navigate('/auth/login');
  };

  const handleProfile = () => {
    console.log('Profile Clicked');
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        show: true,
        title: 'Profile & Settings',
        component: <SettingsPage />,
        className: 'model-md'
      }
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onChangeOrg = (org: any) => {
    if (org._id !== state.selectedOrganisation._id && threadIdParam && categoryIdParam && orgId) {
      window.location.href = `/organisation/${org._id}/category/1/thread/1`;
    } else {
      const lastSegment = window.location.pathname.split('/').pop();
      window.location.href = `/organisation/${org._id}/${lastSegment}`;
    }
  };

  const handleOrganisatioSettings = () => {
      navigate(`/organisation/${state.selectedOrganisation._id}/settings`)
  }

  const hasThread = window.location.pathname.includes('/thread/');

  if (!hasThread) {
  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: theme.palette.gradients.primary,
          ":hover": {
            background: theme.palette.gradients.primaryHover
          },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <DarkModeToggle />
          <div className="header-section flex py-2 px-4 relative items-center w-full justify-between text-white gap-4">
            <div className="flex items-center">
              <a href={'/'}>
                <img
                    src={
                      theme.palette.mode === 'dark'
                          ? selectedOrganisation?.org_dark_logo || selectedOrganisation?.org_logo || logo_white
                          : selectedOrganisation?.org_logo || selectedOrganisation?.org_dark_logo || logo_white
                    }
                    alt="Logo"
                    style={{maxHeight: "30px"}}
                />
              </a>
            </div>
            <div className="flex items-center gap-2">
              <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleHelpClick}
                  sx={{
                    width: 36,
                    height: 36,
                    minWidth: 36,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 0,
                  }}
              >
                <FaQuestionCircle fontSize="1.15rem" />
              </Button>
              <AccountMenu auth={auth} profilePhoto={profilePhoto} handleProfile={handleProfile}
                           handleLogout={handleLogout}/>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <div className="w-96">
            <Box display="flex" justifyContent="center" width={drawerWidth - 65}>
            <Button
              id="fade-button"
              aria-controls={orgOpenMenu ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={orgOpenMenu ? 'true' : undefined}
              onClick={handleOrgMenuClick}
              fullWidth
              endIcon={<ExpandMoreIcon />}
              sx={{ width: "100%" }}
            >
              <Typography noWrap={true}>{state.selectedOrganisation?.name}</Typography>
            </Button>
            </Box>
            <GradientMenu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              sx={{ width: drawerWidth + 10, marginTop: 1, marginLeft: -0.5, marginRight: 0, maxWidth: drawerWidth + 10 }}
              open={orgOpenMenu}
              onClose={handleOrgMenuClose}
              TransitionComponent={Fade}
            >
              {state.organisations.map((org: any) => (
                <GradientMenuItem key={org._id} onClick={() => onChangeOrg(org)}>
                  <Typography noWrap={true}>{org?.name}</Typography>
                </GradientMenuItem>
              ))}
            </GradientMenu>
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        {children}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {outlet}
      </Main>
    </Box>
  ); } else {
    return(
    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
      <CssBaseline />
      {outlet}
    </Box>);
  }
};

export default Header;
