import { useApp, useAuth } from "../../hooks";
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/logos/logo.png';
import { send_join_request } from "../../services/organisation.service";
import React, { useEffect, useState } from "react";
import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Box,
    Typography,
    useTheme
} from "@mui/material";
import {
    ExpandMore,
    Add,
    Star,
    StarBorder,
    DeleteOutline,
    Folder,
    Forum,
    FavoriteBorder
} from "@mui/icons-material";
import {categoryFavouriteService, getAllChatCategoryService} from "../../services/category.service";
import {deleteThreadService, favouriteThreadService, getGeneralThreads} from "../../services/chat.service";

const ChatCategoryMenu = () => {
  const { state, dispatch }: any = useApp();
  const { userChatCategories, selectedOrganisation, generalThreads, selectedChatCategory, selectedThread } = state;
  const navigate = useNavigate();
  const { auth }: any = useAuth();
  const [isTopicsCollapsed, setIsTopicsCollapsed] = useState(true);
  const [isThreadsCollapsed, setIsThreadsCollapsed] = useState(true);
  const [isFavouritesCollapsed, setIsFavouritesCollapsed] = useState(true);
  const { threadIdParam, categoryIdParam, orgId }: any  = useParams();
    const theme = useTheme();

  const [chatTopicSelectedIndex, setChatTopicSelectedIndex] = useState(1);
  const [chatThreadSelectedIndex, setChatThreadSelectedIndex] = useState(1);

  const [generalDialogState, setGeneralDialogState]: any = useState({});


    const DIALOGUESTATE = {
        DELETE_CHAT: 'DELETE_CHAT',
        ADD_TOPIC_TO_FAVORITE: 'ADD_TOPIC_TO_FAVORITE',
        ADD_CHAT_TO_FAVORITE: 'ADD_CHAT_TO_FAVORITE',
        REMOVE_TOPIC_FROM_FAVORITE: 'REMOVE_TOPIC_FROM_FAVORITE',
        REMOVE_CHAT_FROM_FAVORITE: 'REMOVE_CHAT_FROM_FAVORITE',
    }

    const menuStyles = {
        header: {
            py: 1,
            px: 2,
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
        },
        sectionTitle: {
            pl: 2,
            py: 1,
            fontWeight: 600,
            color: theme.palette.text.secondary,
            fontSize: '0.75rem',
            textTransform: 'uppercase'
        },
        listItem: {
            py: '6px',
            px: 2,
            borderRadius: 1,
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
            '&.Mui-selected': {
                backgroundColor: theme.palette.action.selected,
                '&:hover': {
                    backgroundColor: theme.palette.action.selected,
                }
            }
        },
        icon: {
            minWidth: '32px',
            color: theme.palette.text.secondary
        },
        actionIcon: {
            fontSize: '1rem',
            '&:hover': {
                color: theme.palette.primary.main
            }
        }
    };

  const handleCollapseTopicsClick = () => {
    setIsTopicsCollapsed(!isTopicsCollapsed);
  };

  const handleCollapseThreadsClick = () => {
    setIsThreadsCollapsed(!isThreadsCollapsed);
  }

  const handleCollapseFavouritesClick = () => {
    setIsFavouritesCollapsed(!isFavouritesCollapsed);
  }



  // useEffect(() => {
  //   if (!userChatCategories.length) {
  //     routeToNewGeneralThread()
  //   }
  // }, [userChatCategories]);

  useEffect(() => {
    if(categoryIdParam !== 'GENERAL' && selectedChatCategory) {
      const index = userChatCategories.findIndex((ucc: any) => ucc.category_id === categoryIdParam);
      setChatTopicSelectedIndex(index);
      setChatThreadSelectedIndex(-1);
    }
  }, [categoryIdParam]);

  useEffect(() => {
    if(selectedThread && categoryIdParam === 'GENERAL') {
      const index = generalThreads.findIndex((ucc: any) => ucc.thread_id === threadIdParam);
      setChatThreadSelectedIndex(index);
      setChatTopicSelectedIndex(-1);
    }
  }, [threadIdParam]);

  const handleCategoryListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    ucc: any
  ) => {
    // setChatTopicSelectedIndex(index);
    // setChatThreadSelectedIndex(-1);
    onSelectCategory(ucc);
  };

  const handleThreadListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    ucc: any
  ) => {
    // setChatThreadSelectedIndex(index);
    // setChatTopicSelectedIndex(-1);
    onSelectThread(ucc);
  };

  const deleteThread = async () => {
      generalDialogState.metadata.event.stopPropagation();
    console.log('Delete Thread', generalDialogState.metadata.ucc);
    const response = await deleteThreadService(generalDialogState.metadata.ucc.thread_id);
    console.log(response);
    const newThreads = generalThreads.filter((thread: any) => thread.thread_id !== generalDialogState.metadata.ucc.thread_id);

    dispatch({
      type: 'SET_GENERAL_THREADS',
      payload: newThreads
    });

      setGeneralDialogState({
          isOpen: false,
          title: '',
          content: '',
          type: '',
          metadata: {}
      })
  }

  const onSelectCategory = async (cc: any) => {
    try {
      dispatch({
        type: 'SET_USER_ACTIVE_CHAT_CATEGORY',
        payload: cc
      });
      navigate(`/organisation/${selectedOrganisation._id}/category/${cc?.category_id}/thread/${uuid()}`);
    } catch (error) {
      console.error(error);
    }
  }

  const onSelectThread = async (thread: any) => {
    console.log(thread);
    try {
      dispatch({
        type: 'SELECT_THREAD',
        payload: thread
      });
      navigate(`/organisation/${selectedOrganisation._id}/category/${thread?.category_id}/thread/${thread?.thread_id}`);
      //  close the drawer

    } catch (error) {
      console.error(error);
    }
  }

  const routeToCreateTopic = () => {
    navigate(`/organisation/${selectedOrganisation._id}/chat-category`);
  }

  const routeToNewGeneralThread = () => {
    console.log('New General Thread');
    try {
      dispatch({
        type: 'SET_USER_ACTIVE_CHAT_CATEGORY',
        payload: { category_id: 'GENERAL', category_name: 'General' }
      });
      const new_thread_id = uuid();
      const thread = { thread_id: new_thread_id, thread_name: 'New Thread', category_id: 'GENERAL'}
      generalThreads.unshift(thread);
      dispatch({
          type: 'SET_GENERAL_THREADS',
          payload: generalThreads
      });
      const index = generalThreads.findIndex((ucc: any) => ucc.thread_id === new_thread_id);
      dispatch({
        type: 'SELECT_THREAD',
        payload: thread
      });
      setChatThreadSelectedIndex(index);
      setChatTopicSelectedIndex(-1);
      navigate(`/organisation/${selectedOrganisation._id}/category/GENERAL/thread/${new_thread_id}`);
    } catch (error) {
      console.error(error);
    }
  }

  const [maxHeight, setMaxHeight] = useState('0px');

  const updateMaxHeight = () => {
    const availableHeight = window.innerHeight - 300;
    if(favoriteThreads?.length) {
        setMaxHeight(`${availableHeight / 3}px`);
    } else {
        setMaxHeight(`${availableHeight / 2}px`);
    }
  };

  const addCategoryToFavourites = async () => {
    const response = await categoryFavouriteService(
                                              { category_id: generalDialogState.metadata.ucc.category_id,
                                                organisation_id: selectedOrganisation._id, favorite: true,
                                              });
    if (response.status) {

      const categories: any = await getAllChatCategoryService(
          state.selectedOrganisation._id
      );
      dispatch({
        type: 'SET_USER_CHAT_CATEGORIES',
        payload: categories || [],
      });

        dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
            show: true,
            title: "Category Favourite",
            message: "Category added to favourites",
            type: "success",
            },
        });
        } else {
        dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
            show: true,
            title: "Category Favourite",
            message: "Failed to add category to favourites",
            type: "error",
            },
        });
    }

    setGeneralDialogState({
        isOpen: false,
        title: '',
        content: '',
        type: '',
        metadata: {}
    })
  }

  const addThreadToFavourites = async () => {
    favouriteThreadService( generalDialogState.metadata.ucc.thread_id, selectedOrganisation._id , true).then((res: any) => {
        if (res.status === 200) {
            getGeneralThreads().then((res: any) => {
                dispatch({
                    type: 'SET_GENERAL_THREADS',
                    payload: res
                });
            });

            // notify user
            dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
                show: true,
                title: "Thread Favourite",
                message: "Thread added to favourites",
                type: "success",
            },
            });
        } else {
            dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
                show: true,
                title: "Thread Favourite",
                message: "Failed to add thread to favourites",
                type: "error",
            },
            });
        }
    });

      setGeneralDialogState({
          isOpen: false,
          title: '',
          content: '',
          type: '',
          metadata: {}
      })
  }

  const removeThreadFromFavourites = async () => {
    favouriteThreadService( generalDialogState.metadata.ucc.thread_id, selectedOrganisation._id , false).then((res: any) => {
        if (res.status === 200) {
            getGeneralThreads().then((res: any) => {
                dispatch({
                    type: 'SET_GENERAL_THREADS',
                    payload: res
                });
            });


            //   notify user
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              show: true,
              title: "Thread Favourite",
              message: "Thread removed from favourites",
              type: "success",
            },
          });
        } else {
            dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
                show: true,
                title: "Thread Favourite",
                message: "Failed to remove thread from favourites",
                type: "error",
            },
            });
        }
    });

      setGeneralDialogState({
          isOpen: false,
          title: '',
          content: '',
          type: '',
          metadata: {}
      })
  }

  const handleChatDelete = (event: any, index: number, ucc: any) => {
    setGeneralDialogState({
        isOpen: true,
        title: 'Delete Chat',
        content: 'Are you sure you want to delete this chat?',
        type: DIALOGUESTATE.DELETE_CHAT,
        metadata: {
            event: event,
            index: index,
            ucc: ucc,
        }
    });
    }

    const handleGeneralDialogClose = () => {
        setGeneralDialogState({
            isOpen: false,
            title: '',
            content: '',
            type: '',
            metadata: {}
        });
    }

    const handleChatFavourite = (event: any, index: number, ucc: any) => {
        event.stopPropagation();
        setGeneralDialogState({
            isOpen: true,
            title: 'Add Chat to Favourites',
            content: 'Are you sure you want to add this chat to favourites?',
            type: DIALOGUESTATE.ADD_CHAT_TO_FAVORITE,
            metadata: {
                event: event,
                index: index,
                ucc: ucc,
            }
        });
    }

    const handleChatUnFavourite = (event: any, index: number, ucc: any) => {
      event.stopPropagation()
        setGeneralDialogState({
            isOpen: true,
            title: 'Remove Chat from Favourites',
            content: 'Are you sure you want to remove this chat from favourites?',
            type: DIALOGUESTATE.REMOVE_CHAT_FROM_FAVORITE,
            metadata: {
                event: event,
                index: index,
                ucc: ucc,
            }
        });
    }

    const handleCategoryFavourite = (event: any, index: number, ucc: any) => {
        event.stopPropagation();
      setGeneralDialogState({
            isOpen: true,
            title: 'Add Category to Favourites',
            content: 'Are you sure you want to add this category to favourites?',
            type: DIALOGUESTATE.ADD_TOPIC_TO_FAVORITE,
            metadata: {
                event: event,
                index: index,
                ucc: ucc,
            }
        });
    }

    const handleCategoryUnFavourite = (event: any, index: number, ucc: any) => {
        event.stopPropagation();
        setGeneralDialogState({
            isOpen: true,
            title: 'Remove topic from Favourites',
            content: 'Are you sure you want to remove this topic from favourites?',
            type: DIALOGUESTATE.REMOVE_TOPIC_FROM_FAVORITE,
            metadata: {
                event: event,
                index: index,
                ucc: ucc,
            }
        });
    }

  const removeCategoryFromFavourites = async () => {
      const response = await categoryFavouriteService(
        { category_id: generalDialogState.metadata.ucc.category_id ,
          organisation_id: selectedOrganisation._id, favorite: false
        });
    if (response.status) {
      const categories: any = await getAllChatCategoryService(
          state.selectedOrganisation._id
      );
      dispatch({
        type: 'SET_USER_CHAT_CATEGORIES',
        payload: categories || [],
      });

        dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
            show: true,
            title: "Category Favourite",
            message: "Category removed from favourites",
            type: "success",
            },
        });
        } else {
        dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
            show: true,
            title: "Category Favourite",
            message: "Failed to remove category from favourites",
            type: "error",
            },
        });
    }

      setGeneralDialogState({
          isOpen: false,
          title: '',
          content: '',
          type: '',
          metadata: {}
      })
  }

  useEffect(() => {
    window.addEventListener('resize', updateMaxHeight);
    // Initial calculation
    updateMaxHeight();

    return () => window.removeEventListener('resize', updateMaxHeight);
  }, []);

    const combinedThreads = [
        ...userChatCategories.map((category: any) => ({
            ...category,
            type: 'category',
        })),
        ...generalThreads.map((thread: any) => ({
            ...thread,
            type: 'thread',
        })),
    ].sort((a, b) => {
        const aFavorite = a.favourites?.includes(auth.user.uid) || a.favourite;
        const bFavorite = b.favourites?.includes(auth.user.uid) || b.favourite;
        return bFavorite - aFavorite;
    });

    const favoriteThreads = combinedThreads.filter((item: any) => item.favourites?.includes(auth.user.uid) || item.favourite);


    const renderFavorites = () => (
        <Box id="favorites-box">
            <ListItemButton onClick={handleCollapseFavouritesClick} sx={menuStyles.header}>
                <Star sx={{ fontSize: '1rem', mr: 1.5 }} />
                <Typography variant="subtitle2">Favorites</Typography>
                {isFavouritesCollapsed ? <ExpandMore sx={{ fontSize: '1rem', ml: 'auto' }} /> : <ExpandMore sx={{ fontSize: '1rem', ml: 'auto', transform: 'rotate(180deg)' }} />}
            </ListItemButton>
            <Collapse in={isFavouritesCollapsed}>
                <List sx={{ maxHeight: maxHeight, overflow: 'auto' }}>
                    {favoriteThreads.map((item, index) => (
                        <ListItemButton
                            key={index}
                            selected={item.type === 'category' ? chatTopicSelectedIndex === index : chatThreadSelectedIndex === index}
                            onClick={(event) => item.type === 'category'
                                ? handleCategoryListItemClick(event, index, item)
                                : handleThreadListItemClick(event, index, item)}
                            sx={menuStyles.listItem}
                        >
                            <ListItemIcon sx={menuStyles.icon}>
                                {item.type === 'category' ? <Folder sx={{ fontSize: '1rem' }} /> : <Forum sx={{ fontSize: '1rem' }} />}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body2" noWrap>
                                        {item.type === 'category' ? item.category_name : item.thread_name}
                                    </Typography>
                                }
                            />
                            <IconButton
                                size="small"
                                onClick={(event) => item.type === 'category'
                                    ? handleCategoryUnFavourite(event, index, item)
                                    : handleChatUnFavourite(event, index, item)}
                                sx={menuStyles.actionIcon}
                            >
                                <Star sx={{ fontSize: '1rem', color: theme.palette.warning.main }} />
                            </IconButton>
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </Box>
    );

    const renderChatTopics = () => (
        <Box id="chat-topics-box">
            <ListItemButton onClick={handleCollapseTopicsClick} sx={menuStyles.header}>
                <Folder sx={{ fontSize: '1rem', mr: 1.5 }} />
                <Typography variant="subtitle2">Chat Topics</Typography>
                <IconButton
                    size="small"
                    onClick={routeToCreateTopic}
                    sx={{ ml: 'auto', '&:hover': { backgroundColor: 'transparent' } }}
                >
                    <Add sx={{ fontSize: '1rem' }} />
                </IconButton>
            </ListItemButton>
            <Collapse in={isTopicsCollapsed}>
                <List sx={{ maxHeight: maxHeight, overflow: 'auto' }}>
                    {userChatCategories.map((ucc: any, index: any) => {
                        if (ucc.category_id === 'GENERAL') return null;
                        const isFavorite = ucc.favourites?.includes(auth.user.uid);
                        return (
                            <ListItemButton
                                key={index}
                                selected={chatTopicSelectedIndex === index}
                                onClick={(event) => handleCategoryListItemClick(event, index, ucc)}
                                sx={menuStyles.listItem}
                            >
                                <ListItemIcon sx={menuStyles.icon}>
                                    <Folder sx={{ fontSize: '1rem' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body2" noWrap>
                                            {ucc.category_name}
                                        </Typography>
                                    }
                                />
                                <IconButton
                                    size="small"
                                    onClick={(event) => isFavorite
                                        ? handleCategoryUnFavourite(event, index, ucc)
                                        : handleCategoryFavourite(event, index, ucc)}
                                    sx={menuStyles.actionIcon}
                                >
                                    {isFavorite ? (
                                        <Star sx={{ fontSize: '1rem', color: theme.palette.warning.main }} />
                                    ) : (
                                        <StarBorder sx={{ fontSize: '1rem' }} />
                                    )}
                                </IconButton>
                            </ListItemButton>
                        );
                    })}
                    {!userChatCategories.filter((ucc: any) => ucc.category_id !== 'GENERAL').length && (
                        <Box sx={{ textAlign: 'center', py: 2 }}>
                            <FavoriteBorder sx={{ fontSize: '2rem', color: theme.palette.text.disabled }} />
                            <Typography variant="body2" sx={{ color: theme.palette.text.disabled, mt: 1 }}>
                                No topics created yet
                            </Typography>
                        </Box>
                    )}
                </List>
            </Collapse>
        </Box>
    );

    const renderMyChats = () => (
        <Box id="my-chats-box">
            <ListItemButton onClick={handleCollapseThreadsClick} sx={menuStyles.header}>
                <Forum sx={{ fontSize: '1rem', mr: 1.5 }} />
                <Typography variant="subtitle2">My Chats</Typography>
                <IconButton
                    size="small"
                    onClick={routeToNewGeneralThread}
                    sx={{ ml: 'auto', '&:hover': { backgroundColor: 'transparent' } }}
                >
                    <Add sx={{ fontSize: '1rem' }} />
                </IconButton>
            </ListItemButton>
            <Collapse in={isThreadsCollapsed}>
                <List sx={{ maxHeight: maxHeight, overflow: 'auto' }}>
                    {generalThreads.map((ucc: any, index: any) => (
                        <ListItemButton
                            key={index}
                            selected={chatThreadSelectedIndex === index}
                            onClick={(event) => handleThreadListItemClick(event, index, ucc)}
                            sx={menuStyles.listItem}
                        >
                            <ListItemIcon sx={menuStyles.icon}>
                                <Forum sx={{ fontSize: '1rem' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body2" noWrap>
                                        {ucc.thread_name}
                                    </Typography>
                                }
                            />
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                <IconButton
                                    size="small"
                                    onClick={(event) => ucc.favourite
                                        ? handleChatUnFavourite(event, index, ucc)
                                        : handleChatFavourite(event, index, ucc)}
                                    sx={menuStyles.actionIcon}
                                >
                                    {ucc.favourite ? (
                                        <Star sx={{ fontSize: '1rem', color: theme.palette.warning.main }} />
                                    ) : (
                                        <StarBorder sx={{ fontSize: '1rem' }} />
                                    )}
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={(event) => handleChatDelete(event, index, ucc)}
                                    sx={{ ...menuStyles.actionIcon, '&:hover': { color: theme.palette.error.main } }}
                                >
                                    <DeleteOutline sx={{ fontSize: '1rem' }} />
                                </IconButton>
                            </Box>
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </Box>
    );


  return (
      <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          bgcolor: 'background.paper',
          borderRight: `1px solid ${theme.palette.divider}`
      }}>
          <Box sx={{ flex: 1, overflow: 'hidden' }}>
              {favoriteThreads?.length > 0 && renderFavorites()}
              <Divider sx={{ my: 1 }} />
              {renderChatTopics()}
              <Divider sx={{ my: 1 }} />
              {renderMyChats()}
          </Box>

          <Box sx={{
              p: 2,
              textAlign: 'center',
              borderTop: `1px solid ${theme.palette.divider}`
          }}>
              <img
                  src={logo}
                  alt="DatalinkerAI"
                  style={{
                      height: '32px',
                      filter: theme.palette.mode === 'dark' ? 'brightness(0) invert(1)' : 'none'
                  }}
              />
              <Typography
                  variant="caption"
                  sx={{
                      display: 'block',
                      color: theme.palette.text.secondary,
                      mt: 0.5
                  }}
              >
                  v1.1.0
              </Typography>
          </Box>

      {/*<motion.div*/}
      {/*  className="flex items-center justify-center p-2 text-gray-800 bg-gray-200 rounded-lg"*/}
      {/*  initial={{ opacity: 0 }}*/}
      {/*  animate={{ opacity: 1 }}*/}
      {/*  transition={{ duration: 0.5 }}*/}
      {/*>*/}
      {/*  <img src={logo} alt="DatalinkerAI" width={100} />*/}
      {/*  /!*<div className="absolute bottom-1 right-0 bg-red-500 text-white text-xs py-2 px-3 rounded-bl-md">*!/*/}
      {/*  /!*  BETA*!/*/}
      {/*  /!*</div>*!/*/}
      {/*</motion.div>*/}

        <Dialog
            open={generalDialogState.isOpen}
            onClose={handleGeneralDialogClose}
        >
            <DialogTitle>{generalDialogState.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {generalDialogState.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleGeneralDialogClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={generalDialogState.type === DIALOGUESTATE.REMOVE_TOPIC_FROM_FAVORITE ?
                removeCategoryFromFavourites : generalDialogState.type === DIALOGUESTATE.REMOVE_CHAT_FROM_FAVORITE ?
                removeThreadFromFavourites : generalDialogState.type === DIALOGUESTATE.ADD_TOPIC_TO_FAVORITE ?
                addCategoryToFavourites : generalDialogState.type === DIALOGUESTATE.ADD_CHAT_TO_FAVORITE ?
                                addThreadToFavourites :
                                generalDialogState.type === DIALOGUESTATE.DELETE_CHAT ? deleteThread : handleGeneralDialogClose
                } color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    </Box>
  );
}

export default ChatCategoryMenu;


