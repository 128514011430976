import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import {
  FaRegPaperPlane,
  FaTimes,
  FaMicrophone,
  FaPaperclip,
  FaGlobe,
} from 'react-icons/fa';
import { speechToText } from '../../services/chat.service';
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom';
import { useApp, useAuth } from '../../hooks';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import {
  Button,
  CircularProgress,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Google as GoogleIcon } from '@mui/icons-material';
import {
  checkGoogleAuth,
  connectWithGoogle,
} from '../../services/google-tool.service';

import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ArticleIcon from '@mui/icons-material/Article';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ShareIcon from '@mui/icons-material/Share';

interface CustomMentionInputProps {
  zapierActions: any[];
  value: any;
  onChange: (newValue: any) => any;
  onMentionSelect: (mention: any) => any;
  placeholder: string;
  sendMessage: (
      input: string,
      selectedMention: any,
      audioBlob: any,
      files: File[],
      hasInternetAccess?: boolean
  ) => any;
  loading: boolean;
  isCollaborativeThread?: boolean;
  isThreadOwner?: boolean;
  organisation_id: string;
}

const GradientButton = styled(Button)(({ theme }) => ({
  borderRadius: '50%',
  minWidth: 'auto',
  padding: '0.5rem',
  background: theme.palette.gradients.primary,
  color: '#FFFFFF',
  '&:hover': {
    background: theme.palette.gradients.primaryHover,
  },
  '&:active': {
    background: theme.palette.gradients.primaryActive,
  },
  transition: 'all 0.3s ease-in-out',
  boxShadow: `0 4px 14px 0 ${theme.palette.primary}`,
}));

// Identify if substring looks like an email or domain
const EMAIL_REGEX = /[^@\s]+@[^@\s]+\.[^@\s]+/;
function isLikelyEmailOrDomain(str: string): boolean {
  return EMAIL_REGEX.test(str);
}

const DEBOUNCE_DELAY = 200; // ms

const CustomMentionInput = ({
                              zapierActions,
                              value,
                              onChange,
                              onMentionSelect,
                              placeholder,
                              sendMessage,
                              loading,
                              isCollaborativeThread,
                              isThreadOwner,
                              organisation_id,
                            }: CustomMentionInputProps) => {
  const [input, setInput] = useState('');
  const [selectedMentions, setSelectedMentions] = useState<any[]>([]);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const inputRef: any = useRef<HTMLInputElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const recorderRef = useRef<MediaRecorder | null>(null);

  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

  const { dispatch }: any = useApp();
  const { threadIdParam } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { auth }: any = useAuth();

  const [isGoogleAuth, setIsGoogleAuth] = useState(false);
  const [isGoogleAuthChecked, setIsGoogleAuthChecked] = useState(false);

  const [isInternetAccess, setIsInternetAccess] = useState(false);
  const MAX_TOOLS = 3;
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // 1) Check Google Auth once on mount (or whenever organisation_id changes)
  useEffect(() => {
    const doAuthCheck = async () => {
      try {
        const authResult = await checkGoogleAuth(organisation_id);
        setIsGoogleAuth(authResult);
        setIsGoogleAuthChecked(true);
      } catch (error) {
        console.error('Error checking auth:', error);
        setIsGoogleAuth(false);
        setIsGoogleAuthChecked(true);
      }
    };
    doAuthCheck();
  }, [organisation_id]);

  // Clear input whenever thread changes
  useEffect(() => {
    clearInput();
  }, [threadIdParam]);

  // Memoized array of Google Tools
  const googleWorkspaceTools = useMemo(
      () => [
        { id: 'send_email_using_google', display: 'Send email', icon: EmailIcon },
        { id: 'create_calendar_event_in_google_calendar', display: 'Create Calendar Event', icon: EventIcon },
        { id: 'list_calendar_events_from_google_calendar', display: 'List Calendar Events', icon: EventIcon },
        { id: 'update_calendar_event_in_google_calendar', display: 'Update Calendar Event', icon: EventIcon },
        { id: 'get_event_details_from_google_calendar', display: 'Get Event Details', icon: EventIcon },
        // Docs
        { id: 'create_google_doc', display: 'Create Google Doc', icon: NoteAddIcon },
        { id: 'read_google_doc', display: 'Read Google Doc', icon: ArticleIcon },
        { id: 'append_text_to_google_doc', display: 'Append Text to Google Doc', icon: TextFieldsIcon },
        { id: 'replace_text_in_google_doc', display: 'Replace Text in Google Doc', icon: DriveFileRenameOutlineIcon },
        { id: 'list_google_docs', display: 'List Google Docs', icon: DescriptionIcon },
        // Sheets
        { id: 'search_google_sheet_by_name', display: 'Search Google Sheet by Name', icon: DescriptionIcon },
        { id: 'read_google_sheet', display: 'Read Google Sheet', icon: DescriptionIcon },
        { id: 'create_google_sheet', display: 'Create Google Sheet', icon: DescriptionIcon },
        { id: 'manage_google_sheet_data', display: 'Manage Google Sheet Data', icon: DescriptionIcon },
        { id: 'create_graph_from_google_sheet', display: 'Create Graph from Google Sheet', icon: DescriptionIcon },
        // Drive
        { id: 'list_drive_files', display: 'List Drive Files', icon: FolderOpenIcon },
        { id: 'share_drive_file', display: 'Share Drive File', icon: ShareIcon },
      ],
      []
  );

  const debouncedHandleInputChange = useCallback(
      (newValue: string) => {
        const lastAtIndex = newValue.lastIndexOf('@');
        if (lastAtIndex === -1) {
          setIsDropdownOpen(false);
          return;
        }

        if (selectedMentions.length >= MAX_TOOLS) {
          setIsDropdownOpen(false);
          return;
        }

        const mentionText = newValue.slice(lastAtIndex + 1).trim();
        if (isLikelyEmailOrDomain(mentionText)) {
          setIsDropdownOpen(false);
          return;
        }

        if (!isGoogleAuthChecked) {
          setIsDropdownOpen(false);
          return;
        }

        // Build possible suggestions
        let newSuggestions;
        if (isGoogleAuth) {
          newSuggestions = [
            {
              section: 'Google Workspace',
              tools: googleWorkspaceTools,
              showConnect: false,
            },
          ];
        } else {
          newSuggestions = [
            {
              section: 'Google Workspace',
              tools: [],
              showConnect: true,
            },
          ];
        }

        // Filter the tools by mentionText
        const filtered = newSuggestions.map((section: any) => {
          const filteredTools = (section.tools || []).filter((tool: any) =>
              tool.display.toLowerCase().includes(mentionText.toLowerCase())
          );
          return { ...section, tools: filteredTools };
        });

        const totalTools = filtered.reduce(
            (acc: number, sec: any) => acc + (sec.tools?.length || 0),
            0
        );
        const hasConnectButton = filtered.some((sec: any) => sec.showConnect);

        // Only open if there's something to show
        if (totalTools > 0 || hasConnectButton) {
          setSuggestions(filtered);
          setIsDropdownOpen(true);
        } else {
          setIsDropdownOpen(false);
        }
      },
      [
        isGoogleAuth,
        isGoogleAuthChecked,
        googleWorkspaceTools,
        selectedMentions.length,
      ]
  );

  // Fires on every keystroke
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.value;
    setInput(newVal);
    onChange(newVal);

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      debouncedHandleInputChange(newVal);
    }, DEBOUNCE_DELAY);
  };

  // On Enter, send if valid
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
        e.key === 'Enter' &&
        !isDropdownOpen &&
        !e.shiftKey &&
        input.trim() !== '' &&
        input.length > 0
    ) {
      sendMessage(input, selectedMentions[0] ?? {}, audioBlob, attachedFiles, isInternetAccess);
      setTimeout(() => {
        clearInput();
      }, 100);
    }
  };

  /** Remove the '@...' portion from the end of the input. */
  const removeLastMentionTrigger = (text: string) => {
    const lastAtIndex = text.lastIndexOf('@');
    if (lastAtIndex !== -1) {
      return text.slice(0, lastAtIndex).trimEnd();
    }
    return text;
  };

  /** User picked a mention from the list. */
  const selectMention = (suggestion: any) => {
    if (suggestion.id === 'authenticate') {
      connectGoogle();
      return;
    }

    if (selectedMentions.length >= MAX_TOOLS) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          show: true,
          title: 'Max Tools Reached',
          message: `You can attach a maximum of ${MAX_TOOLS} Google tools.`,
          type: 'info',
        },
      });
      return;
    }

    const newMentions = [...selectedMentions, suggestion];
    setSelectedMentions(newMentions);
    onMentionSelect(newMentions);

    // Remove '@...' from input
    const newInput = removeLastMentionTrigger(input);
    setInput(newInput);
    onChange(newInput);
    setIsDropdownOpen(false);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  /** OAuth flow if user is not authenticated */
  const connectGoogle = async () => {
    if (auth?.user?.uid) {
      await connectWithGoogle(auth.user.uid, organisation_id);
      setIsGoogleAuth(true);
    } else {
      console.error('User ID is not available');
    }
    setIsDropdownOpen(false);
  };

  /** Clear everything */
  const clearInput = () => {
    setInput('');
    setSelectedMentions([]);
    onMentionSelect([]);
    setAudioBlob(null);
    setAttachedFiles([]);

    if (inputRef.current) {
      (inputRef.current as HTMLTextAreaElement).style.height = 'auto';
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  /** Remove a mention by ID */
  const removeMention = (mentionId: string) => {
    const newMentions = selectedMentions.filter(
        (m) => m.id !== mentionId
    );
    setSelectedMentions(newMentions);
    onMentionSelect(newMentions);
  };

  /** Attach files */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const newFiles = Array.from(e.target.files);
    const totalFiles = attachedFiles.length + newFiles.length;
    if (totalFiles > 5) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          show: true,
          title: 'File Attachment',
          message: 'You can attach a maximum of 5 files at a time.',
          type: 'error',
        },
      });
      return;
    }

    const validFileTypes = ['pdf', 'docx'];
    let hasInvalid = false;
    const accepted: File[] = [];

    newFiles.forEach((file) => {
      const ext = file.name.split('.').pop()?.toLowerCase();
      if (ext && validFileTypes.includes(ext)) {
        accepted.push(file);
      } else {
        hasInvalid = true;
      }
    });

    if (hasInvalid) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          show: true,
          title: 'Invalid File Type',
          message: 'Only PDF and DOCX files are allowed.',
          type: 'error',
        },
      });
      return;
    }

    setAttachedFiles([...attachedFiles, ...accepted]);
  };

  const removeFile = (index: number) => {
    setAttachedFiles(attachedFiles.filter((_, i) => i !== index));
  };

  /** Start recording audio */
  const startRecording = async () => {
    if (!navigator.mediaDevices?.getUserMedia) {
      console.error('getUserMedia is not supported in this browser.');
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const audioContext = new AudioContext();
    const src = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    src.connect(analyser);

    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    let silenceDuration = 0;
    const silenceThreshold = 130;

    const checkSilence = () => {
      analyser.getByteTimeDomainData(dataArray);
      const maxAmplitude = Math.max(...dataArray);
      if (maxAmplitude < silenceThreshold) {
        silenceDuration += 500;
        if (silenceDuration >= 5000) {
          stopRecording();
        } else {
          setTimeout(checkSilence, 500);
        }
      } else {
        silenceDuration = 0;
        setTimeout(checkSilence, 500);
      }
    };

    recorderRef.current = new MediaRecorder(stream);
    recorderRef.current.ondataavailable = (ev: BlobEvent) => {
      setAudioBlob(ev.data);
      recorderRef.current!.onstop = () => {
        setIsRecording(false);
        sendAudio(ev.data);
      };
    };

    setIsRecording(true);
    recorderRef.current.start();
    checkSilence();

    // Auto-stop at 1 min
    setTimeout(() => stopRecording(), 60000);
  };

  /** Stop audio recording */
  const stopRecording = () => {
    if (recorderRef.current && recorderRef.current.state === 'recording') {
      try {
        recorderRef.current.stream
            .getTracks()
            .forEach((track) => track.stop());
        recorderRef.current.stop();
      } catch (err) {
        console.error('Error stopping recording:', err);
      }
    }
  };

  /** Send audio for speech-to-text */
  const sendAudio = async (voiceData: Blob) => {
    if (!voiceData) return;

    const reader = new FileReader();
    reader.readAsDataURL(voiceData);
    reader.onloadend = async () => {
      try {
        const base64 = reader.result;
        if (base64) {
          const response: any = await speechToText(base64, organisation_id);
          if (response?.data?.data) {
            setInput((prev) => `${prev} ${response.data.data}`);
          }
        }
      } catch (error) {
        console.error('Error sending audio to speechToText:', error);
      }
    };
  };

  // Cleanup
  useEffect(() => {
    return () => {
      if (recorderRef.current && recorderRef.current.state === 'recording') {
        recorderRef.current.stop();
      }
      if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    };
  }, []);

  // Tally how many tools remain after filtering
  const totalFilteredTools = useMemo(() => {
    return suggestions.reduce(
        (acc: number, sec: any) => acc + (sec.tools?.length || 0),
        0
    );
  }, [suggestions]);

  const hasConnectButton = useMemo(() => {
    return suggestions.some((sec: any) => sec.showConnect);
  }, [suggestions]);

  return (
      <Paper
          elevation={3}
          sx={{
            p: 2,
            borderRadius: 1,
            position: 'relative',
            boxShadow: (theme) =>
                `0 4px 14px 0 ${theme.palette.gradients.primaryActive}`,
          }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          {/* Desktop Tools + Main Input */}
          <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              gap={1}
              alignItems="flex-start"
          >
            {!isMobile && (
                <Box display="flex" alignItems="center" gap={1}>
                  <Tooltip title="Attach Files">
                    <IconButton
                        id="attach-button"
                        onClick={() => fileInputRef.current?.click()}
                        disabled={loading}
                    >
                      <GradientButton>
                        <FaPaperclip size={20} />
                      </GradientButton>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={`Internet Access: ${isInternetAccess ? 'On' : 'Off'}`}>
                    <IconButton
                        id="internet-button"
                        onClick={() => setIsInternetAccess(!isInternetAccess)}
                        disabled={loading}
                    >
                      <GradientButton
                          style={{ color: isInternetAccess ? '#90EE90' : 'white' }}
                      >
                        <FaGlobe size={20} />
                      </GradientButton>
                    </IconButton>
                  </Tooltip>
                </Box>
            )}

            {/* Main TextField */}
            <TextField
                id="chat-input-box"
                label={placeholder}
                multiline
                maxRows={4}
                inputRef={inputRef}
                value={input}
                fullWidth
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                variant="outlined"
                InputProps={{
                  style: { resize: 'none' },
                }}
                inputProps={{
                  maxLength: 25000,
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    flexDirection: 'column-reverse',
                  },
                }}
            />

            {!isMobile && (
                <Box display="flex" alignItems="center" gap={1}>
                  <Tooltip title={isRecording ? 'Stop Recording' : 'Record Audio'}>
                    <IconButton
                        id="mic-record-button"
                        onClick={isRecording ? stopRecording : startRecording}
                        disabled={loading}
                    >
                      <GradientButton>
                        {isRecording ? <FaTimes size={20} /> : <FaMicrophone size={20} />}
                      </GradientButton>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Send">
                    <IconButton
                        id="send-message-button"
                        onClick={() => {
                          if (input.trim() !== '' && input.length > 0) {
                            sendMessage(
                                input,
                                selectedMentions ?? {},
                                audioBlob,
                                attachedFiles,
                                isInternetAccess
                            );
                            clearInput();
                          }
                        }}
                        disabled={loading || (!isCollaborativeThread && !isThreadOwner)}
                    >
                      <GradientButton>
                        {loading ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            <FaRegPaperPlane size={20} />
                        )}
                      </GradientButton>
                    </IconButton>
                  </Tooltip>
                </Box>
            )}
          </Box>

          {/* Mobile Tools */}
          {isMobile && (
              <Box display="flex" justifyContent="flex-end" gap={1} mt={1}>
                <Tooltip title="Attach Files (PDF/DOCX)">
                  <IconButton
                      onClick={() => fileInputRef.current?.click()}
                      disabled={loading}
                  >
                    <GradientButton>
                      <FaPaperclip size={20} />
                    </GradientButton>
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Internet Access: ${isInternetAccess ? 'On' : 'Off'}`}>
                  <IconButton
                      onClick={() => setIsInternetAccess(!isInternetAccess)}
                      disabled={loading}
                  >
                    <GradientButton
                        style={{ color: isInternetAccess ? '#90EE90' : 'white' }}
                    >
                      <FaGlobe size={20} />
                    </GradientButton>
                  </IconButton>
                </Tooltip>
                <Tooltip title={isRecording ? 'Stop Recording' : 'Record Audio'}>
                  <IconButton
                      onClick={isRecording ? stopRecording : startRecording}
                      disabled={loading}
                  >
                    <GradientButton>
                      {isRecording ? <FaTimes size={20} /> : <FaMicrophone size={20} />}
                    </GradientButton>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Send">
                  <IconButton
                      onClick={() => {
                        if (input.trim() !== '' && input.length > 0) {
                          sendMessage(
                              input,
                              selectedMentions[0] ?? {},
                              audioBlob,
                              attachedFiles,
                              isInternetAccess
                          );
                          clearInput();
                        }
                      }}
                      disabled={loading || (!isCollaborativeThread && !isThreadOwner)}
                  >
                    <GradientButton>
                      {loading ? (
                          <CircularProgress size={20} color="inherit" />
                      ) : (
                          <FaRegPaperPlane size={20} />
                      )}
                    </GradientButton>
                  </IconButton>
                </Tooltip>
              </Box>
          )}

          {/* Selected Mentions */}
          {selectedMentions.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                {selectedMentions.map((mention: any, idx: number) => (
                    <Chip
                        key={idx}
                        label={mention.display}
                        onDelete={() => removeMention(mention.id)}
                        color="primary"
                        icon={mention?.icon ? <mention.icon /> : undefined}
                    />
                ))}
              </Box>
          )}
        </Box>

        {/* Hidden File Input */}
        <input
            ref={fileInputRef}
            type="file"
            multiple
            accept=".pdf,.docx"
            style={{ display: 'none' }}
            onChange={handleFileChange}
        />

        {/* Attached Files */}
        {attachedFiles.length > 0 && (
            <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
              {attachedFiles.map((file, index) => (
                  <Chip
                      key={index}
                      label={file.name}
                      onDelete={() => removeFile(index)}
                      color="secondary"
                      sx={{ margin: '0.5rem' }}
                  />
              ))}
            </Box>
        )}

        {/* Mentions Dropdown */}
        {isDropdownOpen && (totalFilteredTools > 0 || hasConnectButton) && (
            <Paper
                elevation={3}
                sx={{
                  position: 'absolute',
                  left: 0,
                  bottom: '100%',
                  zIndex: 50,
                  width: '100%',
                  maxHeight: '300px',
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: theme.palette.background.paper,
                }}
            >
              <Box display="flex" justifyContent="flex-end" p={1}>
                <IconButton
                    size="small"
                    onClick={() => setIsDropdownOpen(false)}
                >
                  <FaTimes />
                </IconButton>
              </Box>

              {suggestions.map((section: any, idx: number) => (
                  <Box key={idx}>
                    <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 1,
                          borderBottom: '1px solid',
                          borderColor: 'divider',
                          backgroundColor: 'background.default',
                        }}
                    >
                      <Box sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                        {section.section}
                      </Box>
                      {section.showConnect && (
                          <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={connectGoogle}
                              startIcon={<GoogleIcon />}
                          >
                            Connect
                          </Button>
                      )}
                    </Box>
                    {section.tools && section.tools.length > 0 && (
                        <List sx={{ py: 0 }}>
                          {section.tools.map((tool: any) => (
                              <ListItem
                                  button
                                  key={tool.id}
                                  onClick={() => selectMention(tool)}
                              >
                                <ListItemIcon>
                                  {tool.icon && <tool.icon />}
                                </ListItemIcon>
                                <ListItemText primary={tool.display} />
                              </ListItem>
                          ))}
                        </List>
                    )}
                  </Box>
              ))}
            </Paper>
        )}
      </Paper>
  );
};

export default CustomMentionInput;
