export const initialState: any = {
  model: {
    show: false,
    title: '',
    component: null,
    className: ''
  },
  conformation: {
    show: false,
    onYes: null,
    onNo: null,
    header: '',
    title: ''
  },
  notification: {
    show: false,
    title: '',
    message: '',
    type: ''
  },
  drawer: {
    toggle: false,
    title: '',
    component: null
  },
  showFaq: false,
  spentMatrix: false,
  matrixData: [],
  user: {},
  organisations: [],
  selectedOrganisation: {},
  organisation: {},
  isOrganisationNotExist: false,
  chatCategories: [],
  databases: [],
  files: [],
  pendingRequests: [],
  userChatCategories: [],
  selectedChatCategory: {},
  faqListItems: [
    {
      question: 'What are the health and safety guidelines?'
    },
    {
      question: 'Where can I smoke?'
    },
    {
      question: 'How can I apply for jobs?'
    },
    {
      question: 'What are leave guidelines?'
    }
  ],
  selectedFaq : '',
  threadId: '',
  zapierActions: [],
  generalThreads: [],
  selectedModel: 'gpt-4o',
  isCollaborativeThread: false,
  kValue: 10,
  userPermissions: []
}