import axiosApiInstance from "./request"

const createRoleService = async ( organisationId: string, name: any, description: any, permission_ids: any) => {
    try {
        const response = await axiosApiInstance.post('v2/roles/create',
            {organisation_id: organisationId,
                role_name: name,
                role_description: description,
                permission_ids: permission_ids
            })
        return response.data
    } catch (error: any) {
        return error.response.data
    }
}

const getRolesService = async ( organisationId: string) => {
    try {
        const response = await axiosApiInstance.post('v2/roles/organisation', {organisation_id: organisationId})
        return response.data.data
    } catch (error: any) {
        return error.response?.data
    }
}

const deleteRoleService = async ( roleId: string) => {
    try {
        const response = await axiosApiInstance.post('v2/roles/delete', {role_id: roleId})
        return response.data
    } catch (error: any) {
        return error.response.data
    }
}

const updateRoleService = async ( roleId: string, name: any, description: any, permission_ids: any, organisation_id: any) => {
    try {
        const response = await axiosApiInstance.post('v2/roles/update',
            {role_id: roleId,
                role_name: name,
                role_description: description,
                permission_ids: permission_ids,
                organisation_id: organisation_id
            })
        return response.data
    } catch (error: any) {
        return error.response.data
    }
}

const assignRoleService = async ( userId: string, roleId: string, org_id: string) => {
    try {
        const response = await axiosApiInstance.post('v2/roles/assign',
            {user_id: userId,
                role_id: roleId,
                org_id: org_id
            })
        return response.data
    } catch (error: any) {
        return error.response.data
    }
}

export {
    createRoleService,
    getRolesService,
    deleteRoleService,
    updateRoleService,
    assignRoleService
}

