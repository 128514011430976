import axios from "axios";

const axiosApiInstance = axios.create();

const getNewToken = async (refreshToken: string) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}v1/refresh-session`, { refresh_token: refreshToken })
    const { id_token, refresh_token } = res.data.data
    return { id_token: id_token, refresh_token: refresh_token }
  } catch (error) {
    return { id_token: '', refresh_token: '' }
  }
}

axiosApiInstance.interceptors.request.use(
  async config => {
    config.baseURL = process.env.REACT_APP_API_BASE_URL
    const headers: any = {
      'Authorization': `${localStorage.getItem('idToken')}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-ORG-ID': `${localStorage?.getItem('orgId')}`
    }
    config.headers = headers
    return config;
  },
  error => {
    Promise.reject(error)
  });

axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const { config, response } = error
  if (response.status === 401) {
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      const { id_token, refresh_token } = await getNewToken(refreshToken)
      if (id_token) {
        localStorage.setItem('idToken', id_token)
        localStorage.setItem('refreshToken', refresh_token)

        config.headers['Authorization'] = id_token
        config.headers['X-ORG-ID'] = `${localStorage?.getItem('orgId')}`
        return axiosApiInstance.request(config);
      } else {
        window.localStorage.removeItem('idToken');
		    window.localStorage.removeItem('refreshToken');
        window.location.href = '/auth/login'
      }
    } else {
      window.localStorage.removeItem('idToken');
		  window.localStorage.removeItem('refreshToken');
      window.location.href = '/auth/login'
    }
  } else {
    return Promise.reject(error);
  }
});

export default axiosApiInstance;
