import { useEffect, useState } from "react";
import { useApp } from "../../hooks";
import { assignRoleService } from "../../services/roles.service";
import { getRolesService } from "../../services/roles.service";
import {
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  CircularProgress,
  Typography,
  Box,
  Alert,
  Slide,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const EditUserForm = ({ userData, refreshMembersData }: any) => {
  const { state, dispatch }: any = useApp();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [roles, setRoles] = useState<any[]>([]);
  const [selectedRole, setSelectedRole] = useState<any>(null); // Changed initial state
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (success || error) {
      setOpenAlert(true);
      const timer = setTimeout(() => {
        setOpenAlert(false);
        setError("");
        setSuccess("");
      }, 3000); // Alert disappears after 3 seconds

      return () => clearTimeout(timer); // Clear timeout if component unmounts
    }
  }, [success, error]);

  const fetchRoles = async () => {
    console.log("User Data", userData);
    setLoading(true);
    try {
      const response = await getRolesService(state.selectedOrganisation._id);
      if (response?.data) {
        const roles = response.data.filter((role: any) => !role.hide);
        if (roles.length > 0) {
          setRoles(roles);
          if (userData.role) {
            const initialRole = roles.find(
                (role: any) => role.role_id === userData.userInfo.role_ids[0]
            );
            setSelectedRole(initialRole || null);
          }
        } else
          setRoles([])
      } else {
        setRoles([]);
      }
    } catch (err: any) {
      setError(err.message || "Failed to fetch roles.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [state.selectedOrganisation._id, userData.role]); // Added dependencies

  const onRoleChange = (event: any) => {
    const role = roles.find((r: any) => r.role_id === event.target.value);
    setSelectedRole(role);
  };

  const updateUser = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    if (!selectedRole) {
      setError("Please select a role for the user.");
      setLoading(false);
      return;
    }

    try {
        const response: any = await assignRoleService(
          userData.user_id,
          selectedRole.role_id,
            state.selectedOrganisation._id,
        );
        console.log(response);
        const { success, data } = response;
        if (success) {
          dispatch({
            type: "SHOW_MODAL",
            payload: { show: false, title: "", component: null, className: "" },
          });
          dispatch({
            type: "SET_NOTIFICATION",
            payload: {
              show: true,
              title: "Update User",
              message: "User role updated.",
              type: "success",
            },
          });
          refreshMembersData();
        } else {
          setError("Failed to update user role.");
        }
    } catch (err: any) {
      setError(err.message || "Failed to update user role.");
    } finally {
      setLoading(false);
    }
  };

  return (
      <Box>
        <Typography variant="h6" gutterBottom>
          Edit User Role
        </Typography>
        <Typography variant="subtitle1">
          Update the role for <b>{userData.userInfo.display_name}</b>. Choose
          the appropriate role from the options below.
        </Typography>

        <Slide direction="down" in={openAlert} mountOnEnter unmountOnExit>
          <Box mt={2}>
            {success && (
                <Alert
                    icon={<CheckCircleIcon fontSize="inherit" />}
                    severity="success"
                >
                  {success}
                </Alert>
            )}
            {error && (
                <Alert
                    icon={<ErrorIcon fontSize="inherit" />}
                    severity="error"
                >
                  {error}
                </Alert>
            )}
          </Box>
        </Slide>

        <Box mt={2}>
          <FormControl fullWidth>
            <InputLabel id="role-select-label">Select Role</InputLabel>
            <Select
                labelId="role-select-label"
                id="role-select"
                value={selectedRole ? selectedRole.role_id : ""}
                onChange={onRoleChange}
                label="Select Role"
                disabled={loading}
            >
              {roles.map((role: any) => (
                  <MenuItem key={role.role_id} value={role.role_id}>
                    {role.role_name}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button
              variant="contained"
              color="primary"
              onClick={updateUser}
              disabled={loading || !selectedRole} // Disable if loading or no role selected
          >
            {loading ? (
                <CircularProgress size={24} />
            ) : (
                "Update User Role"
            )}
          </Button>
        </Box>
      </Box>
  );
};

export default EditUserForm;
