import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useApp, useAuth } from "../../hooks";
import {
    dismissCategoryService,
    assignCategoryService,
    dismissRoleFromCategoryService,
    assignRoleToCategoryService, getChatCategoryById
} from "../../services/category.service";
import { getUsersInfoByIds } from "../../services/auth.service";
import { searchUsersService } from "../../services/auth.service";
import { getRolesService } from "../../services/roles.service";
import {
    CircularProgress,
    TableContainer,
    Table,
    Paper,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Button,
    Autocomplete,
    TextField,
    Select,
    MenuItem,
    Chip,
    Typography,
    Box
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FaUserTimes, FaUserTag } from "react-icons/fa";
import debounce from "lodash.debounce";

const ChatCategoryManagement = ({ category }: any) => {
    const { state, dispatch }: any = useApp();
    const { auth }: any = useAuth();
    const { selectedOrganisation } = state;

    // Table state
    const [categoryAssignments, setCategoryAssignments] = useState<any[]>([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [tableLoading, setTableLoading] = useState(true);

    // Assignment state
    const [assignmentType, setAssignmentType] = useState<'USER' | 'ROLE'>('USER');
    const [userOptions, setUserOptions] = useState<any[]>([]);
    const [roleOptions, setRoleOptions] = useState<any[]>([]);
    const [roles, setRoles] = useState<any[]>([]);
    const [selectedAssignment, setSelectedAssignment] = useState<any>(null);
    const [error, setError] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [assignLoading, setAssignLoading] = useState(false);

    const fetchCategoryAssignments = async () => {
        setTableLoading(true);
        try {

            console.log("Category: ", category);

            // Fetch Category
            const response = await getChatCategoryById(category.category_id, selectedOrganisation._id);
            console.log("Response: ", response);
            // Fetch users
            const usersResponse: any = await getUsersInfoByIds(
                response?.users || [],
                pagination.pageSize,
                pagination.pageIndex + 1
            );
            const users = (usersResponse.data?.data || []).map((user: any) => ({ ...user, type: 'USER' }));
            console.log("Category: ", category);
            // Fetch roles
            console.log("Users: ", users);
            const rolesResponse = await getRolesService(selectedOrganisation._id);
            const rolesInCategory = (response?.roles || []).map((roleId: string) =>
                rolesResponse.data.find((role: any) => role.role_id === roleId)
            ).filter(Boolean);
            const roles = rolesInCategory.map((role: any) => ({ ...role, type: 'ROLE' }));

            setCategoryAssignments([...users, ...roles]);
        } finally {
            setTableLoading(false);
        }
    };

    useEffect(() => {
        if (category) fetchCategoryAssignments();
    }, [category, pagination]);

    const fetchRoles = async () => {
        try {
            const response = await getRolesService(selectedOrganisation._id);
            if (response?.data) {
                setRoles(response.data.filter((role: any) => !role.hide));
                setRoleOptions(response.data.filter((role: any) => !role.hide).map((role: any) => ({
                    label: role.role_name,
                    value: role.role_id,
                    type: 'ROLE'
                })));
            }
        } catch (err) {
            console.error('Failed to fetch roles:', err);
        }
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    const handleSearchUsers = useCallback(debounce(async (keyword: string) => {
        if (!keyword || keyword.length < 3) return;

        setSearchLoading(true);
        try {
            const response: any = await searchUsersService(keyword, selectedOrganisation._id);
            setUserOptions(response.data.map((user: any) => ({
                label: `${user.display_name} (${user.email})`,
                value: user.user_id,
                type: 'USER'
            })));
        } finally {
            setSearchLoading(false);
        }
    }, 300), [selectedOrganisation]);

    const handleRoleSearch = (keyword: string) => {
        const filtered = roles.filter(role =>
            role.role_name.toLowerCase().includes(keyword.toLowerCase())
        );
        setRoleOptions(filtered.map(role => ({
            label: role.role_name,
            value: role.role_id,
            type: 'ROLE'
        })));
    };

    const handleAssign = async () => {
        if (!selectedAssignment) {
            setError('Please select a user or role');
            return;
        }

        setAssignLoading(true);
        try {
            const payload = {
                [selectedAssignment.type === 'USER' ? 'user_id' : 'role_id']: selectedAssignment.value,
                category_name: category.category_name,
                organisation_id: selectedOrganisation._id
            };

            const service = selectedAssignment.type === 'USER'
                ? assignCategoryService
                : assignRoleToCategoryService;

            const { status, message } = await service(payload);
            console.log("Assign response: ", status, message);
            if (status) {
                dispatch({
                    type: 'SET_NOTIFICATION',
                    payload: { show: true, title: 'Category Update', message: `${selectedAssignment.type === 'USER' ? 'User Assigned' : 'Role Assigned'}` ,type: 'success' }
                });
                setSelectedAssignment(null);
                fetchCategoryAssignments();
            } else {
                setError(message);
            }
        } finally {
            setAssignLoading(false);
        }
    };

    const handleRemoveAssignment = async (assignment: any) => {
        const payload = {
            [assignment.type === 'USER' ? 'user_id' : 'role_id']: assignment.type === 'USER'
                ? assignment.user_id
                : assignment.role_id,
            category_name: category.category_name,
            organisation_id: selectedOrganisation._id
        };

        const service = assignment.type === 'USER'
            ? dismissCategoryService
            : dismissRoleFromCategoryService;

        const { status, message } = await service(payload);
        if (status) {
            dispatch({
                type: 'SET_NOTIFICATION',
                payload: { show: true, title: 'Category Updated', message: `${assignment.type === 'USER' ? 'Removed user' : 'Removed role'}`, type: 'success' }
            });
            fetchCategoryAssignments();
        } else {
            dispatch({
                type: 'SET_NOTIFICATION',
                payload: { show: true, title: 'Category Updated', message: `${assignment.type === 'USER' ? 'Unable to remove user' : 'Unable to remove role'}`, type: 'success' }
            });
        }
    };

    const columns = useMemo(() => [
        {
            accessorKey: 'type',
            header: 'Type',
            cell: ({ row }: any) => (
                <Chip
                    label={row.original.type}
                    color={row.original.type === 'USER' ? 'primary' : 'secondary'}
                    variant="outlined"
                    size="small"
                />
            )
        },
        {
            accessorKey: 'name',
            header: 'Name',
            cell: ({ row }: any) => row.original.type === 'USER'
                ? row.original.display_name
                : row.original.role_name
        },
        {
            accessorKey: 'email',
            header: 'Email',
            cell: ({ row }: any) => row.original.type === 'USER' ? row.original.email : '—'
        },
        {
            accessorKey: 'action',
            header: 'Action',
            cell: ({ row }: any) => (
                (row.original.type === 'USER' && row.original.user_id !== auth.user.uid) ||
                row.original.type === 'ROLE' ? (
                    <Button
                        onClick={() => handleRemoveAssignment(row.original)}
                        color="error"
                        size="small"
                    >
                        <FaUserTimes size={20} />
                    </Button>
                ) : null
            )
        }
    ], [auth]);

    const handlePageChange = (newPage: number) => {
        setPagination(prev => ({ ...prev, pageIndex: newPage }));
    };

    return (
        <Box id="assign-box" sx={{ p: 4, minWidth: 800, minHeight: 600, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
                Chat Topic: {category?.category_name}
            </Typography>

            <Box sx={{ mb: 4, bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 1 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ mb: 2 }}>
                    Add Users or Roles to this Chat Topic
                </Typography>

                <Box id="name-box" sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Select
                        id="function-select"
                        value={assignmentType}
                        onChange={(e) => setAssignmentType(e.target.value as 'USER' | 'ROLE')}
                        sx={{ width: 180 }}
                    >
                        <MenuItem value="USER">Assign User</MenuItem>
                        <MenuItem id="role-option" value="ROLE">Assign Role</MenuItem>
                    </Select>

                    <Autocomplete
                        options={assignmentType === 'USER' ? userOptions : roleOptions}
                        fullWidth
                        onInputChange={(_, value) => {
                            assignmentType === 'USER'
                                ? handleSearchUsers(value)
                                : handleRoleSearch(value);
                        }}
                        onChange={(_, value) => {
                            setSelectedAssignment(value);
                            setError('');
                        }}
                        loading={searchLoading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={assignmentType === 'USER'
                                    ? "Search users..."
                                    : "Search roles..."}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {searchLoading && <CircularProgress size={20} />}
                                            {params.InputProps.endAdornment}
                                        </>
                                    )
                                }}
                            />
                        )}
                    />

                    <LoadingButton
                        variant="contained"
                        loading={assignLoading}
                        onClick={handleAssign}
                        disabled={!selectedAssignment}
                        sx={{ height: 56, minWidth: 120 }}
                    >
                        Add {assignmentType === 'USER' ? 'User' : 'Role'}
                    </LoadingButton>
                </Box>

                {error && (
                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                        {error}
                    </Typography>
                )}
            </Box>

            <Box sx={{ flex: 1, overflow: 'hidden' }}>
                <TableContainer id="table-container" component={Paper} sx={{ height: '100%', boxShadow: 1 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.accessorKey} sx={{
                                        fontWeight: "bold",
                                        bgcolor: 'background.default'
                                    }}>
                                        {column.header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {tableLoading ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                                        <CircularProgress />
                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            Loading...
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ) : categoryAssignments.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                                        <FaUserTag size={40} style={{ opacity: 0.5, marginBottom: 8 }} />
                                        <Typography variant="body2">
                                            No users or roles assigned to this category
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                categoryAssignments.map((assignment: any) => (
                                    <TableRow key={`${assignment.type}-${assignment.user_id || assignment.role_id}`} hover>
                                        {columns.map((column) => (
                                            <TableCell key={column.accessorKey}>
                                                {column.cell
                                                    ? column.cell({ row: { original: assignment } })
                                                    : assignment[column.accessorKey]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 2,
                        borderTop: 1,
                        borderColor: 'divider'
                    }}>
                        <LoadingButton
                            disabled={pagination.pageIndex === 0}
                            onClick={() => handlePageChange(pagination.pageIndex - 1)}
                            variant="outlined"
                        >
                            Previous
                        </LoadingButton>

                        <Typography variant="body2">
                            Page {pagination.pageIndex + 1}
                        </Typography>

                        <LoadingButton
                            disabled={categoryAssignments.length < pagination.pageSize}
                            onClick={() => handlePageChange(pagination.pageIndex + 1)}
                            variant="outlined"
                        >
                            Next
                        </LoadingButton>
                    </Box>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default ChatCategoryManagement;