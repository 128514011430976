import React, {useCallback, useEffect, useMemo, useState} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useApp } from '../../hooks';
import { accept_join_request, get_all_pending_requests, get_user_invitation_link, reject_join_request, removeUserFromOrgService } from '../../services/organisation.service';
import EditUserInfoForm from './EditUserInfoForm';
import Tooltip from '@mui/material/Tooltip';
import { getUsersInfo } from '../../services/auth.service';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import BadgeIcon from '@mui/icons-material/Badge';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import ForumIcon from '@mui/icons-material/Forum';
import StorageIcon from '@mui/icons-material/Storage';
import CollectionsIcon from '@mui/icons-material/Collections';
import BusinessIcon from '@mui/icons-material/Business';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ModuleRegistry, themeQuartz, colorSchemeDarkBlue, colorSchemeLightCold, InfiniteRowModelModule } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {useTheme} from "@mui/material/styles";
import Spinner from "../shared/Spinner";
import {createRoleService, deleteRoleService, getRolesService, updateRoleService} from "../../services/roles.service";
import PermissionGuard from "../../guards/PermissionGuard";
import {useCheckPermissions} from "../../guards/ProtectedRoute";
ModuleRegistry.registerModules([InfiniteRowModelModule]);


const fetchUsers = async (orgId: any, pagination: any): Promise<any> => {
  const response: any = await getUsersInfo(orgId, pagination.pageSize, pagination.pageIndex);
  return response?.data;
};

const OrganisationUsers: React.FC<any> = () => {
  const { state, dispatch }: any = useApp();
  const [activeTab, setActiveTab]: any = useState(0);
  const [editUserData, setEditUserData]: any = useState(null);
  const [isModalOpen, setIsModalOpen]: any = useState(false);
  const [isDialogOpen, setIsDialogOpen]: any = useState(false);
  const [userToDelete, setUserToDelete]: any = useState(null);

  const [isRoleModalOpen, setIsRoleModalOpen]: any = useState(false);
  const [roles, setRoles]: any = useState([]);
  const [roleName, setRoleName] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    const [isUpdateRole, setIsUpdateRole] = useState(false);
    const [updateRoleID, setUpdateRoleID] = useState('');

    const [membersLoading, setMembersLoading]: any = useState(false);


  // ag-grid
  const themeLightCold = themeQuartz.withPart(colorSchemeLightCold);
  const themeDarkBlue = themeQuartz.withPart(colorSchemeDarkBlue);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const appTheme = useTheme();
  const defaultColDef = useMemo(() => {
    return {

      flex: 1,

    };
  }, []);

    const permissions = [
                {
                    "id": "update_org_settings",
                    "description": "Manage organization details and branding (name, logo, etc.)"
                },
                {
                    "id": "view_edit_member_list",
                    "description": "View organization members"
                },
                {
                    "id": "edit_user_role",
                    "description": "Modify user roles and permissions"
                },
                {
                    "id": "delete_user",
                    "description": "Remove users from organization"
                },
                {
                    "id": "view_action_join_requests",
                    "description": "View organization join requests"
                },
                {
                    "id": "approve_join_request",
                    "description": "Authorize new member requests"
                },
                {
                    "id": "reject_join_request",
                    "description": "Deny incoming member requests"
                },
                {
                    "id": "view_roles_section",
                    "description": "Access role management interface"
                },
                {
                    "id": "create_role",
                    "description": "Create new permission sets and roles"
                },
                {
                    "id": "edit_role",
                    "description": "Modify existing role configurations"
                },
                // {
                //     "id": "delete_role",
                //     "description": "Remove existing roles from system"
                // },
                {
                    "id": "view_billing",
                    "description": "Access billing info and token usage"
                },
                {
                    "id": "edit_purchase_tokens",
                    "description": "Manage token purchases"
                },
                // {
                //     "id": "edit_cancel_plan",
                //     "description": "Modify or terminate subscription plans"
                // },
                {
                    "id": "view_chat_topics",
                    "description": "Access to chat topic listings"
                },
                {
                    "id": "edit_chat_topics",
                    "description": "Update existing chat topic configurations"
                },
                {
                    "id": "delete_chat_topic",
                    "description": "Remove chat topic permanently"
                },
                {
                    "id": "share_chat_topic",
                    "description": "Share chat topics with other users"
                },
                {
                    "id": "add_chat_topics",
                    "description": "Create new chat topics"
                },
                {
                    "id": "view_drives",
                    "description": "View drive listings and configurations"
                },
                {
                    "id": "edit_drives",
                    "description": "Modify existing drive configurations"
                },
                {
                    "id": "delete_drive",
                    "description": "Permanently remove drives"
                },
                {
                    "id": "add_drive",
                    "description": "Create new drives"
                },
                {
                    "id": "view_media",
                    "description": "Access media listings and configurations"
                },
                {
                    "id": "download_media",
                    "description": "Download media assets"
                },
                {
                    "id": "delete_media",
                    "description": "Permanently remove media assets"
                }
            ]



    const actionCellRenderer = (params: any) => {
        if (params.data?.role !== 'owner') {
            return (

                <div className="flex items-center justify-center space-x-2 py-2">
                    <PermissionGuard requiredPermissions={['delete_user']}>
                        <Tooltip title="Delete User">
                            <Button onClick={() => handleOpenDialog(params.data)}>
                                <DeleteIcon/>
                            </Button>
                        </Tooltip>
                    </PermissionGuard>
                    <PermissionGuard requiredPermissions={['edit_user_role']}>
                        <Tooltip title="Edit User">
                            <Button onClick={() => handleUserEdit(params.data)}>
                                <EditIcon/>
                            </Button>
                        </Tooltip>
                    </PermissionGuard>
                </div>
            );
        } else {
                <div></div>
        }
    }

  const [colDefs, setColDefs]: any = useState([
      {
          headerName: 'Index',
          field: 'id',
          sortable: false,
          filter: false,
          flex: 0.4
      },
      {
          headerName: 'Name',
          field: 'userInfo.display_name',
          sortable: false,
          filter: false,
          flex: 1.3,
      },
      {
          headerName: 'Email',
          field: 'userInfo.email',
          sortable: false,
          filter: false,
          flex: 1.3,
      },
      {
        headerName: 'Role',
        field: 'userInfo.role_name',
        sortable: false,
        filter: false,
        flex: 1,
      },
      {
          headerName: 'Action',
          cellRenderer: actionCellRenderer,
          sortable: false,
          autoHeight: true
          // filter: false,
      },
  ]);

    const fetchRoles = async () => {
        const response = await getRolesService(state.selectedOrganisation._id);
        if(response?.data) {
            const roles = response.data.filter((role: any) => !role.hide);
            if (roles.length > 0)
                setRoles(roles);
            else
                setRoles([]);
        }
        else
            setRoles([]);
    };

    useEffect(() => {
        const handleExternalTabChange = (event: Event) => {
            const customEvent = event as CustomEvent<number>;
            setActiveTab(customEvent.detail);
        };

        window.addEventListener('switchTab-users', handleExternalTabChange);
        return () => window.removeEventListener('switchTab-users', handleExternalTabChange);
    }, []);

    useEffect(() => {
        fetchRoles();
    }, []);

    const handleCreateRole = () => {
        setRoleName('');
        setRoleDescription('');
        setSelectedPermissions([]);
        setIsUpdateRole(false);
        setUpdateRoleID('');
        setIsRoleModalOpen(true);
    };

    const handleCloseRoleModal = () => {
        setIsRoleModalOpen(false);
    };

    const handleSaveRole = async () => {
        if(isUpdateRole) {
          const response =  await updateRoleService(updateRoleID, roleName, roleDescription, selectedPermissions, state.selectedOrganisation._id);
            if (response.success === 1) {
                dispatch({
                    type: "SET_NOTIFICATION",
                    payload: {
                        show: true,
                        title: "Update Role",
                        message: "Role updated successfully",
                        type: "success",
                    },
                });
                setIsRoleModalOpen(false);
                fetchRoles();
            } else {
                dispatch({
                    type: "SET_NOTIFICATION",
                    payload: {
                        show: true,
                        title: "Update Role",
                        message: "Error updating role",
                        type: "error",
                    },
                });
            }
        } else {
            const response = await createRoleService(state.selectedOrganisation._id, roleName, roleDescription, selectedPermissions);
            if (response.success === 1) {
                dispatch({
                    type: "SET_NOTIFICATION",
                    payload: {
                        show: true,
                        title: "Create Role",
                        message: "Role created successfully",
                        type: "success",
                    },
                });
                setIsRoleModalOpen(false);
                fetchRoles();
            } else {
                dispatch({
                    type: "SET_NOTIFICATION",
                    payload: {
                        show: true,
                        title: "Create Role",
                        message: "Error creating role",
                        type: "error",
                    },
                });
            }
        }

        setIsModalOpen(false);
    };

    const [membersGridApi, setMembersGridApi]: any = useState(null);

    const refreshMembersInfiniteCache = () => {
        if (membersGridApi) {
            membersGridApi.refreshInfiniteCache();
        }
    };



    const onGridReadyForMembers = useCallback((params: any) => {
        const { pageSize } = pagination;
        const { selectedOrganisation } = state;
        setMembersGridApi(params.api);

        const dataSource = {
            rowCount: undefined,
            getRows: async (params: any) => {
                console.log("asking for " + params.startRow + " to " + params.endRow);

                const currentPageNumber = Math.floor(params.endRow / pageSize);
                let lastRow = -1;

                try {
                    setMembersLoading(true);
                    const data = await fetchUsers(selectedOrganisation._id, {
                        pageIndex: currentPageNumber,
                        pageSize: pageSize,
                    });

                    let list = data.data;

                    console.log("Data: ", list);

                    list.forEach((item: any, index: number) => {
                        item.id = (params.startRow + index + 1);
                        item.userInfo.role_name = item.userInfo.permissions.find((permission: any) => permission.organisation_id === selectedOrganisation._id)?.role_name ?? 'Staff';
                    });

                    if (list.length < pageSize) {
                        lastRow = params.startRow + list.length;
                    }

                    setMembersLoading(false);

                    params.successCallback(list, lastRow);
                } catch (error) {
                    console.error("Error fetching data: ", error);
                    params.failCallback();
                }
            },
        };

        params.api.setGridOption("datasource", dataSource);
    }, [pagination.pageSize, state.selectedOrganisation._id]);



    const requestsColDefs: any = useMemo(
        () => [
            {
                headerName: 'Name',
                field: 'user_info.display_name',
                sortable: false,
                filter: false,
                floatingFilter: false,
            },
            {
                headerName: 'Email',
                field: 'user_info.email',
                sortable: false,
                filter: false,
                floatingFilter: false,
            },
            {
                headerName: 'Requested On',
                field: 'requested_on',
                sortable: false,
                filter: false,
                floatingFilter: false,
            },
            {
                headerName: 'Action',
                cellRenderer: (params: any) => {
                    return (
                        <div className="flex gap-4">
                            <PermissionGuard requiredPermissions={['approve_join_request']}>
                            <Tooltip title="Accept User">
                                <Button onClick={() => handleUserAccept(params.data)}>
                                    <CheckCircleIcon />
                                </Button>
                            </Tooltip>
                            </PermissionGuard>
                            <PermissionGuard requiredPermissions={['reject_join_request']}>
                            <Tooltip title="Reject User">
                                <Button onClick={() => handleUserReject(params.data)}>
                                    <CancelIcon />
                                </Button>
                            </Tooltip>
                            </PermissionGuard>
                        </div>
                    );
                },
                sortable: false,
                filter: false,
                autoHeight: true
            },
        ],
        []
    );


  const handleUserAccept = async (userData: any): Promise<void> => {
    const response: any = await accept_join_request(userData.user_id, state.selectedOrganisation._id);
    if (response?.data?.success === 1) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Accept User",
          message: "User accepted successfully",
          type: "success",
        },
      });
      const pendingRequests: any = await get_all_pending_requests(state.selectedOrganisation._id);
      if (pendingRequests?.data.success === 1) {
        dispatch({ type: "SET_PENDING_REQUESTS", payload: pendingRequests?.data?.data });
      }
    } else {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Accept User",
          message: response?.data.message,
          type: "error",
        },
      });
    }
  };

  const handleUserReject = async (userData: any): Promise<void> => {
    const response: any = await reject_join_request(userData.user_id, state.selectedOrganisation._id);
    if (response?.data.success === 1) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Reject User",
          message: "User join request rejected",
          type: "success",
        },
      });
      const pendingRequests: any = await get_all_pending_requests(state.selectedOrganisation._id);
      if (pendingRequests?.data.success === 1) {
        dispatch({ type: "SET_PENDING_REQUESTS", payload: pendingRequests?.data?.data });
      }
    } else {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Reject User",
          message: response?.data.message,
          type: "error",
        },
      });
    }
  };



  useEffect((): void => {
    const fetchPendingRequests = async (): Promise<void> => {
      const pendingRequests: any = await get_all_pending_requests(state.selectedOrganisation._id);
      if (pendingRequests?.data.success === 1) {
        dispatch({ type: "SET_PENDING_REQUESTS", payload: pendingRequests?.data?.data });
      }
    };
    fetchPendingRequests();
  }, [state.selectedOrganisation._id]);

  const handleOpenDialog = (userData: any): void => {
    setUserToDelete(userData);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (): void => {
    setIsDialogOpen(false);
    setUserToDelete(null);
  };

  const handleDeleteUser = async (): Promise<void> => {
    if (userToDelete) {
      await handleOrgUserDelete(userToDelete);
      handleCloseDialog();
    }
  };

  const handleOrgUserDelete = async (userData: any): Promise<void> => {
    try {
      if (userData.role === "owner") {
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: "Remove User",
            message: "You cannot remove the owner",
            type: "error",
          },
        });
      } else {
        const { status, message }: any = await removeUserFromOrgService(userData.user_id, state.selectedOrganisation._id);
        if (status) {
          dispatch({
            type: "SHOW_MODAL",
            payload: { show: false, title: "", component: null, className: "" },
          });
          dispatch({
            type: "SET_NOTIFICATION",
            payload: {
              show: true,
              title: "Remove User",
              message: "User removed successfully",
              type: "success",
            },
          });
        //   reload user data
            refreshMembersInfiniteCache();
        } else {
          dispatch({
            type: "SET_NOTIFICATION",
            payload: {
              show: true,
              title: "Remove User",
              message: message,
              type: "error",
            },
          });
        }
      }
    } catch (error: any) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Remove User",
          message: "Something went wrong!",
          type: "error",
        },
      });
    }
  };

  const handleUserEdit = (userData: any): void => {
    setEditUserData(userData);
    setIsModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
    setEditUserData(null);
  };

    const handleEditRole = (role: any) => {
        setRoleName(role.role_name);
        setRoleDescription(role.role_description);
        setSelectedPermissions(role.permission_ids);
        setUpdateRoleID(role.role_id);
        setIsUpdateRole(true);
        setIsRoleModalOpen(true);

    }

    const handleDeleteRole = async (role: any) => {
        console.log('Delete Role: ', role
        );
        const response = await deleteRoleService(role.role_id);
        if (response.success === 1) {
            dispatch({
                type: "SET_NOTIFICATION",
                payload: {
                    show: true,
                    title: "Delete Role",
                    message: "Role deleted successfully",
                    type: "success",
                },
            });
            fetchRoles();
        } else {
            dispatch({
                type: "SET_NOTIFICATION",
                payload: {
                    show: true,
                    title: "Delete Role",
                    message: "Error deleting role",
                    type: "error",
                },
            });
        }

    }

  const copyInviteLink = async (): Promise<void> => {
    const inviteLink: any = await get_user_invitation_link(state.selectedOrganisation._id);
    navigator.clipboard.writeText(inviteLink);
    dispatch({
      type: "SET_NOTIFICATION",
      payload: {
        show: true,
        title: "Copy Link",
        message: "Link copied to clipboard",
        type: "success",
      },
    });
  };

  const [pendingRequestData, setPendingRequestData]: any = useState([]);

    useEffect(() => {
        console.log("Pending Requests: ", state.pendingRequests);
        if(state?.pendingRequests?.length > 0)
            setPendingRequestData(state.pendingRequests);
        else
            setPendingRequestData([]);
    }, [state.pendingRequests]);

  const renderContent = (): any => {
    if (activeTab === 0) {
      return (

          <div id="tab0" style={{width: "100%", height: "80vh", marginTop: "10px"}}>
                  <AgGridReact
                      key="members"
                      columnDefs={colDefs}
                      defaultColDef={defaultColDef}
                      onGridReady={onGridReadyForMembers}
                      rowModelType="infinite"
                      cacheBlockSize={pagination.pageSize}
                      theme={appTheme.palette.mode === 'dark' ? themeDarkBlue : themeLightCold}
                      pagination={false}
                      loading={membersLoading}
                  />
            </div>

      );
    } else if (activeTab === 1) {
        return (
            <div id="tab1" style={{ width: '100%', height: '80vh', marginTop: '10px' }}>
                {state.pendingRequests ? (
                    <AgGridReact
                        key="pending-requests"
                        rowData={pendingRequestData}
                        columnDefs={requestsColDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={() => {}}
                        rowModelType="clientSide"
                        theme={appTheme.palette.mode === 'dark' ? themeDarkBlue : themeLightCold}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20, 50, 100]}
                        noRowsOverlayComponent={() => {return <div>No pending requests</div>}}
                    />
                ) : (
                    <Spinner />
                )}
            </div>
        );
    } else if (activeTab === 2) {
        return (
            <div id="tab2">
                <PermissionGuard requiredPermissions={['create_role']}>
                <Button id="create-role-button" variant="contained" color="primary" onClick={handleCreateRole}>
                    Create Role
                </Button>
                </PermissionGuard>
                <div style={{width: '100%', height: '80vh', marginTop: '10px'}}>
                    <AgGridReact
                        rowData={roles}
                        columnDefs={[
                            {headerName: 'Role Name', field: 'role_name', sortable: true, filter: true, flex: 1},
                            {headerName: 'Description', field: 'role_description', sortable: true, filter: true, flex: 1},
                        //     edit role
                        //     delete role
                            {headerName: 'Action', cellRenderer: (params: any) => {
                                return (
                                    <div className="flex items-center justify-center space-x-2 py-2">
                                        <PermissionGuard requiredPermissions={['edit_role']}>
                                        <Tooltip title="Edit Role">
                                            <Button onClick={() => handleEditRole(params.data)}>
                                                <EditIcon />
                                            </Button>
                                        </Tooltip>
                                        </PermissionGuard>
                                        {/*<PermissionGuard requiredPermissions={['delete_role']}>*/}
                                        {/*<Tooltip title="Delete Role">*/}
                                        {/*    <Button onClick={() => handleDeleteRole(params.data)}>*/}
                                        {/*        <DeleteIcon />*/}
                                        {/*    </Button>*/}
                                        {/*</Tooltip>*/}
                                        {/*</PermissionGuard>*/}
                                    </div>
                                );
                                }, autoHeight: true
                            }
                        ]}
                        defaultColDef={{flex: 1}}
                        theme={appTheme.palette.mode === 'dark' ? themeDarkBlue : themeLightCold}
                    />
                </div>
            </div>
        );
    }
  };

    const handleTabChange = (event: any, newValue: any): void => {
        setActiveTab(newValue);
        window.dispatchEvent(new CustomEvent('switchTab-users', { detail: newValue }));
    };

    const viewEditMemberList = [ 'edit_user_role', 'delete_user'];
    const viewRolesList = ['create_role', 'edit_role', 'delete_role'];
    const viewJoinRequestsList = ['approve_join_request', 'reject_join_request'];
    const viewDrivesList = ['edit_drives', 'delete_drive', 'add_drive'];
    const viewMediaList = ['download_media', 'delete_media'];
    const viewChatTopicsList = ['edit_chat_topics', 'delete_chat_topic', 'share_chat_topic', 'add_chat_topics'];
    const viewBillingList = [ 'edit_purchase_tokens', 'edit_cancel_plan'];

    const checkIfDefaultPermissionCanBeRemoved = (permission: any) => {
        if(permission.id === 'view_edit_member_list') {
            if(!selectedPermissions.some((id) => viewEditMemberList.includes(id))) {
                setSelectedPermissions(selectedPermissions.filter((id) => id !== 'view_edit_member_list'));
            } else {
                dispatch({
                    type: "SET_NOTIFICATION",
                    payload: {
                        show: true,
                        title: "Remove Permission",
                        message: "This permission cannot be removed as it is required for other permissions.",
                        type: "error",
                    },
                });
            }
            return true;
        }

        if(permission.id === 'view_roles_section') {
            if(!selectedPermissions.some((id) => viewRolesList.includes(id))) {
                setSelectedPermissions(selectedPermissions.filter((id) => id !== 'view_roles_section'));
            } else {
                dispatch({
                    type: "SET_NOTIFICATION",
                    payload: {
                        show: true,
                        title: "Remove Permission",
                        message: "This permission cannot be removed as it is required for other permissions.",
                        type: "error",
                    },
                });
            }
            return true;
        }

        if(permission.id === 'view_action_join_requests') {
            if(!selectedPermissions.some((id) => viewJoinRequestsList.includes(id))) {
                setSelectedPermissions(selectedPermissions.filter((id) => id !== 'view_action_join_requests'));
            } else {
                dispatch({
                    type: "SET_NOTIFICATION",
                    payload: {
                        show: true,
                        title: "Remove Permission",
                        message: "This permission cannot be removed as it is required for other permissions.",
                        type: "error",
                    },
                });
            }
            return true;
        }

        if(permission.id === 'view_drives') {
            if(!selectedPermissions.some((id) => viewDrivesList.includes(id))) {
                setSelectedPermissions(selectedPermissions.filter((id) => id !== 'view_drives'));
            } else {
                dispatch({
                    type: "SET_NOTIFICATION",
                    payload: {
                        show: true,
                        title: "Remove Permission",
                        message: "This permission cannot be removed as it is required for other permissions.",
                        type: "error",
                    },
                });
            }
            return true;
        }

        if(permission.id === 'view_media') {
            if(!selectedPermissions.some((id) => viewMediaList.includes(id))) {
                setSelectedPermissions(selectedPermissions.filter((id) => id !== 'view_media'));
            } else {
                dispatch({
                    type: "SET_NOTIFICATION",
                    payload: {
                        show: true,
                        title: "Remove Permission",
                        message: "This permission cannot be removed as it is required for other permissions.",
                        type: "error",
                    },
                });
            }
            return true;
        }

        if(permission.id === 'view_chat_topics') {
            if(!selectedPermissions.some((id) => viewChatTopicsList.includes(id))) {
                setSelectedPermissions(selectedPermissions.filter((id) => id !== 'view_chat_topics'));
            } else {
                dispatch({
                    type: "SET_NOTIFICATION",
                    payload: {
                        show: true,
                        title: "Remove Permission",
                        message: "This permission cannot be removed as it is required for other permissions.",
                        type: "error",
                    },
                });
            }
            return true;
        }

        if(permission.id === 'view_billing') {
            if(!selectedPermissions.some((id) => viewBillingList.includes(id))) {
                setSelectedPermissions(selectedPermissions.filter((id) => id !== 'view_billing'));
            } else {
                dispatch({
                    type: "SET_NOTIFICATION",
                    payload: {
                        show: true,
                        title: "Remove Permission",
                        message: "This permission cannot be removed as it is required for other permissions.",
                        type: "error",
                    },
                });
            }
            return true;
        }

        setSelectedPermissions(selectedPermissions.filter((id) => id !== permission.id));
        return false;
    }




    const checkIfOneOfDefaultPermissionNeedsToBeAdded = (permission: any) => {



        if(permission.id === 'view_edit_member_list' || permission.id === 'view_drives' || permission.id === 'view_media' || permission.id === 'view_chat_topics' || permission.id === 'view_billing' || permission.id === 'view_action_join_requests' || permission.id === 'view_roles_section') {
            if(!selectedPermissions.includes(permission.id)) {
                setSelectedPermissions([...selectedPermissions, permission.id]);
            }
            return true;
        }

        if(viewEditMemberList.includes(permission.id) ) {
            if(!selectedPermissions.includes('view_edit_member_list'))
                setSelectedPermissions([...selectedPermissions, 'view_edit_member_list', permission.id]);
            else
                setSelectedPermissions([...selectedPermissions, permission.id]);
            return true;
        }

        if(viewRolesList.includes(permission.id) ) {
            if(!selectedPermissions.includes('view_roles_section'))
                setSelectedPermissions([...selectedPermissions, 'view_roles_section', permission.id]);
            else
                setSelectedPermissions([...selectedPermissions, permission.id]);
            return true;
        }

        if(viewJoinRequestsList.includes(permission.id) ) {
            if(!selectedPermissions.includes('view_action_join_requests'))
                setSelectedPermissions([...selectedPermissions, 'view_action_join_requests', permission.id]);
            else
                setSelectedPermissions([...selectedPermissions, permission.id]);
            return true;
        }

        if(viewDrivesList.includes(permission.id)) {
            if(!selectedPermissions.includes('view_drives'))
                setSelectedPermissions([...selectedPermissions, 'view_drives', permission.id]);
            else
                setSelectedPermissions([...selectedPermissions, permission.id]);
            return true;
        }

        if(viewMediaList.includes(permission.id)) {
            if(!selectedPermissions.includes('view_media'))
                setSelectedPermissions([...selectedPermissions, 'view_media', permission.id]);
            else
                setSelectedPermissions([...selectedPermissions, permission.id]);
            return true;
        }

        if(viewChatTopicsList.includes(permission.id)) {
            if(!selectedPermissions.includes('view_chat_topics'))
                setSelectedPermissions([...selectedPermissions, 'view_chat_topics', permission.id]);
            else
                setSelectedPermissions([...selectedPermissions, permission.id]);
            return true;
        }

        if(viewBillingList.includes(permission.id)) {
            if(!selectedPermissions.includes('view_billing'))
                setSelectedPermissions([...selectedPermissions, 'view_billing', permission.id]);
            else
                setSelectedPermissions([...selectedPermissions, permission.id]);
            return true;
        }

        if(permission.id === 'update_org_settings') {
            if(!selectedPermissions.includes(permission.id)) {
                setSelectedPermissions([...selectedPermissions, permission.id]);
            }
        }
        return false;

    }

    const PermissionSection = ({ title, icon, permissions }: any) => (
        <Box sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '30vh',
            overflowY: 'auto',
            boxShadow: (theme) => theme.shadows[1]
        }}>
            <Box sx={{
                p: 2,
                borderBottom: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0
            }}>
                {React.cloneElement(icon, { sx: { mr: 1.5, color: 'text.secondary' } })}
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    {title}
                </Typography>
            </Box>

            <Box sx={{
                overflowY: 'auto',
                flex: 1,
                p: 1,
                '&::-webkit-scrollbar': { width: '6px' },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'divider',
                    borderRadius: '4px'
                }
            }}>
                {permissions.map((permission: any) => (
                    <FormControlLabel
                        key={permission.id}
                        control={
                            <Checkbox
                                size="small"
                                color="primary"
                                checked={selectedPermissions.includes(permission.id)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        checkIfOneOfDefaultPermissionNeedsToBeAdded(permission);
                                    } else {
                                        checkIfDefaultPermissionCanBeRemoved(permission);

                                    }
                                }}
                            />
                        }
                        label={<Typography variant="body2" sx={{ lineHeight: 1.3 }}>{permission.description.replace(/\(.*?\)/g, '').replace(':', '')}</Typography>}
                        sx={{ mx: 0, px: 1.5, py: 1, '&:hover': { bgcolor: 'action.hover', borderRadius: 1 } }}
                    />
                ))}
            </Box>
        </Box>
    );

    return (
        <div className="flex flex-col">
            <div className="flex items-center justify-between">
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="Members" id="members" disabled={!useCheckPermissions(['view_edit_member_list'], 'all')}/>
                    <Tab label="Join Requests" id="join-requests" disabled={!useCheckPermissions(['view_action_join_requests'], 'all')}/>
                    <Tab label={"Roles"} disabled={!useCheckPermissions(['view_roles_section'], 'all')}/>
                </Tabs>
                <div className="flex justify-end items-center space-x-2">
                    <Button id="invite-users" variant="contained" color="secondary" onClick={copyInviteLink}>
                        <ContentCopyIcon sx={{marginRight: 1}}/>
                        Copy Invitation Link
                    </Button>
                </div>
        </div>
        {renderContent()}
            <Modal
                open={isRoleModalOpen}
                onClose={handleCloseRoleModal}
                aria-labelledby="role-creation-modal"
                sx={{ backdropFilter: 'blur(2px)' }}
            >
                <Box
                    id="roles-modal"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '95%', md: '85%' },
                        maxWidth: 1200,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        maxHeight: '95vh',
                        borderRadius: 2,
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    {/* Header Section */}
                    <Box sx={{
                        p: 3,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        flexShrink: 0
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <SettingsIcon color="primary" sx={{ mr: 1.5 }} />
                            <Typography variant="h6" component="div">
                                Create New Role
                            </Typography>
                        </Box>

                        <Box component="form" sx={{ mt: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="role-name-box"
                                        fullWidth
                                        required
                                        label="Role Name"
                                        variant="outlined"
                                        placeholder="e.g., Chat Topic Manager"
                                        InputProps={{
                                            startAdornment: <BadgeIcon fontSize="small" sx={{ mr: 1.5, color: 'action.active' }} />
                                        }}
                                        value={roleName}
                                        onChange={(e) => setRoleName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="role-description-box"
                                        fullWidth
                                        required
                                        label="Role Description"
                                        variant="outlined"
                                        multiline
                                        rows={1}
                                        placeholder="Briefly describe this role's purpose"
                                        InputProps={{
                                            startAdornment: <DescriptionIcon fontSize="small" sx={{ mr: 1.5, color: 'action.active' }} />
                                        }}
                                        value={roleDescription}
                                        onChange={(e) => setRoleDescription(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    {/* Permissions Container */}
                    <Box sx={{
                        flex: 1,
                        overflow: 'hidden',
                        p: 2,
                        bgcolor: 'background.default'
                    }}>
                        <Grid container spacing={2} sx={{ height: '100%' }}>
                            {/* Chat Topics Section */}
                            <Grid id="chat-topic-permissions" item xs={12} md={4} sx={{ height: '100%' }}>
                                <PermissionSection
                                    title="Chat Topics"
                                    icon={<ForumIcon fontSize="small" />}
                                    permissions={permissions.filter(p => p.id.includes('chat'))}
                                />
                            </Grid>

                            {/* Drives Section */}
                            <Grid item xs={12} md={4} sx={{ height: '100%' }}>
                                <PermissionSection
                                    title="Drives Management"
                                    icon={<StorageIcon fontSize="small" />}
                                    permissions={permissions.filter(p => p.id.includes('drive'))}
                                />
                            </Grid>

                            {/* Media Section */}
                            <Grid item xs={12} md={4} sx={{ height: '100%' }}>
                                <PermissionSection
                                    title="Media Control"
                                    icon={<CollectionsIcon fontSize="small" />}
                                    permissions={permissions.filter(p => p.id.includes('media'))}
                                />
                            </Grid>

                            {/* Organization Section */}
                            <Grid item xs={12} md={6} sx={{ height: '100%' }}>
                                <PermissionSection
                                    title="Organization Settings"
                                    icon={<BusinessIcon fontSize="small" />}
                                    permissions={permissions.filter(p =>
                                        p.id.includes('org') ||
                                        p.id === 'contact_us' ||
                                        p.id.includes('member') ||
                                        p.id.includes('join_request') ||
                                        p.id.includes('role') ||
                                        p.id.includes('user')
                                    )}
                                />
                            </Grid>

                            {/* Billing Section */}
                            <Grid item xs={12} md={6} sx={{ height: '100%' }}>
                                <PermissionSection
                                    title="Billing & Subscriptions"
                                    icon={<AttachMoneyIcon fontSize="small" />}
                                    permissions={permissions.filter(p =>
                                        p.id.includes('billing') ||
                                        p.id.includes('tokens') ||
                                        p.id.includes('plan')
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Footer Actions */}
                    <Box sx={{
                        p: 2,
                        borderTop: '1px solid',
                        borderColor: 'divider',
                        bgcolor: 'background.default',
                        flexShrink: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Button
                            id="cancel-button"
                            onClick={handleCloseRoleModal}
                            variant="outlined"
                            startIcon={<CancelIcon />}
                            sx={{ minWidth: 120 }}
                        >
                            Cancel
                        </Button>
                        {/*if isUpdateRole then show update button*/}
                        {isUpdateRole ? (
                            <Button
                                variant="contained"
                                onClick={handleSaveRole}
                                startIcon={<CheckCircleIcon />}
                                sx={{ minWidth: 140 }}
                            >
                                Update Role
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                onClick={handleSaveRole}
                                startIcon={<CheckCircleIcon />}
                                sx={{ minWidth: 140 }}
                            >
                                Create Role
                            </Button>
                        )}
                    </Box>
                </Box>
            </Modal>


        <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="edit-user-modal-title"
            aria-describedby="edit-user-modal-description"
        >
          <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}
          >
            {editUserData && <EditUserInfoForm userData={editUserData} refreshMembersData={refreshMembersInfiniteCache} />}
          </Box>
        </Modal>
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this user? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteUser} color="secondary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
};


export default OrganisationUsers;
