import { useApp } from "../hooks";

const PermissionGuard = ({
                             requiredPermissions = [],
                             checkType = 'all',
                             children
                         }: any) => {
    const { state }: any = useApp();
    const { userPermissions } = state;

    const hasPermission = checkType === 'all'
        ? requiredPermissions.every((p: any) => userPermissions.includes(p))
        : requiredPermissions.some((p: any) => userPermissions.includes(p));

    return hasPermission ? children : null;
};

export default PermissionGuard;