import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useApp } from "../hooks";

interface ProtectedRouteProps {
    requiredPermissions?: string[];
    checkType?: 'any' | 'all';
    redirectPath?: string;
    children: ReactNode;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
    requiredPermissions = [],
    checkType = 'all',
    redirectPath = '/unauthorized',
    children
}) => {
    const { state }: any = useApp();
    const { userPermissions } = state;

    const hasPermission = checkType === 'all'
        ? requiredPermissions.every((p: string) => userPermissions.includes(p))
        : requiredPermissions.some((p: string) => userPermissions.includes(p));

    return hasPermission ? <>{children}</> : <Navigate to={redirectPath} replace />;
};

export const useCheckPermissions = (required: string[], checkType: 'any' | 'all') => {
    const { state }: any = useApp();
    const { userPermissions } = state;

    if (required.length === 0) return true;

    return checkType === 'all'
        ? required.every((p: string) => userPermissions.includes(p))
        : required.some((p: string) => userPermissions.includes(p));
};

export default ProtectedRoute;