import {
  FaDatabase,
  FaEdit,
  FaExclamationTriangle,
  FaInfoCircle,
  FaPlus,
  FaRegCreditCard,
} from "react-icons/fa";
import { useApp, useAuth } from "../../hooks";
import React, { useEffect, useState } from "react";
import CustomProgressBar from "./CustomProgressBar";
import { motion } from "framer-motion";
import TokenTopupForm from "./TokenTopupForm";
import {
  getBillingSession,
  getCustomerSession,
  getOrganisationBalance,
  getOrganisationSubscription,
} from "../../services/payment.service";
import { Tooltip } from "react-tooltip";
import {
  Box,
  Button,
  Grid,
  Typography,
  Modal,
  Paper,
  CircularProgress,
} from "@mui/material";
import PermissionGuard from "../../guards/PermissionGuard";

import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import {FaQuestionCircle} from "react-icons/fa";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const BillingComponent = () => {
  const { state, dispatch }: any = useApp();
  const { auth }: any = useAuth();
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState<any>(null);
  const [balanceInfo, setBalanceInfo] = useState<any>(null);
  const [userRole, setUserRole] = useState("");

  const [customerSession, setCustomerSession]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);
  const [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    const fetchSubsctiptionInfo = async () => {
      try {
        const response: any = await getOrganisationSubscription(
          state.selectedOrganisation._id
        );
        const data = await response?.data;
        const { role } = state.selectedOrganisation.members.find(
          (m: any) => m.user_id === auth.user.user_id
        );
        setUserRole(role);
        if (data?.success === 1) {
          const organisationBalance: any = await getOrganisationBalance(
            state.selectedOrganisation._id
          );
          const balanceData = await organisationBalance.data;
          if (balanceData.success === 1) {
            setBalanceInfo(balanceData.data.response);
          }
          setHasSubscription(true);
          setSubscriptionInfo(data.data.response);
        } else {
          try {
            const response: any = await getCustomerSession(
              state.selectedOrganisation._id,
              auth.user.email
            );
            const data = await response?.data;
            setCustomerSession(data?.client_secret_key);
          } catch (error) {
            console.error("Failed to fetch billing info:", error);
          }
        }
      } catch (error) {
        console.error("Failed to fetch organisation subscription:", error);
      }
    };
    fetchSubsctiptionInfo();
  }, []);

  const loadMoreResource = (item: any) => {
    console.log("Load more resource:", item);
    let model = "";
    let type = "";
    switch (item.title) {
      case "GPT-3.5 Turbo Input Tokens":
        model = "gpt-3.5-turbo";
        type = "input";
        break;
      case "GPT-3.5 Turbo Output Tokens":
        model = "gpt-3.5-turbo";
        type = "output";
        break;
      case "GPT-4 Input Tokens":
        model = "gpt-4";
        type = "input";
        break;
      case "GPT-4 Output Tokens":
        model = "gpt-4";
        type = "output";
        break;
      case "GPT-4 Turbo Input Tokens":
        model = "gpt-4-turbo";
        type = "input";
        break;
      case "GPT-4 Turbo Output Tokens":
        model = "gpt-4-turbo";
        type = "output";
        break;
      case "Storage (MB)":
        model = "storage";
        type = "input";
        break;
      case "GPT-4o Input Tokens":
        model = "gpt-4o";
        type = "input";
        break;
      case "GPT-4o Output Tokens":
        model = "gpt-4o";
        type = "output";
        break;
      case "Gemini-1.5-Pro Input Tokens":
        model = "gemini-1.5-pro";
        type = "input";
        break;
      case "Gemini-1.5-Pro Output Tokens":
        model = "gemini-1.5-pro";
        type = "output";
        break;
      case "Gemini-1.5-Flash Input Tokens":
        model = "gemini-1.5-flash";
        type = "input";
        break;
      case "Gemini-1.5-Flash Output Tokens":
        model = "gemini-1.5-flash";
        type = "output";
        break;
      case "Claude-Opus Input Tokens":
        model = "claude-opus";
        type = "input";
        break;
      case "Claude-Opus Output Tokens":
        model = "claude-opus";
        type = "output";
        break;
      case "Text to Speech (Characters)":
        model = "tts-1";
        type = "limit";
        break;
      case "Text to Speech - HD (Characters)":
        model = "tts-1";
        type = "hd_limit";
        break;
      case "Speech to Text - (Seconds)":
        model = "whisper-1";
        type = "limit";
        break;

      default:
        break;
    }
    setModalTitle("Topup: " + item.title);
    setModalContent(<TokenTopupForm model={model} type={type} />);
    setOpenModal(true);
  };

  const manage_billing = async () => {
    try {
      const response: any = await getBillingSession(
        state.selectedOrganisation._id,
        auth.user.email
      );
      const data = await response?.data;
      window.location.href = data.billing_url;
    } catch (error) {
      console.error("Failed to fetch billing info:", error);
    }
  };

  useEffect(() => {
    if (
      customerSession?.length > 0 &&
      customerSession !== null &&
      customerSession !== undefined
    ) {
      setLoading(false);
    }
  }, [customerSession]);

  useEffect(() => {
    if (hasSubscription && subscriptionInfo !== null && balanceInfo !== null) {
      setLoading(false);
    }
  }, [hasSubscription, subscriptionInfo, balanceInfo]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent(null);
  };

  return (
    <motion.div
      className="min-h-screen p-6 overflow-auto h-96"

      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        className="grid grid-cols-2 gap-4"
        initial={{ x: 50 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        {!loading && (
          <div>
            {hasSubscription ? (
              <motion.h1
                className="mb-2"
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="flex items-center">
                  <div className="text-2xl font-bold">
                    Manage Your Billing
                  </div>
                  <div className="flex justify-center items-center pl-2 cursor-pointer">
                    <a
                      data-tooltip-id="update-billing-info"
                      data-tooltip-content="Update your billing information and track your subscriptions with ease."
                      data-tooltip-delay-show={1000}
                    >
                      <FaInfoCircle className="text-lg" />
                    </a>
                  </div>
                  <Tooltip id="update-billing-info" />
                </div>
              </motion.h1>
            ) : (
              <motion.h1
                className="mb-2"
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="flex items-center">
                  <div className="text-2xl font-bold">
                    Choose Your Plan
                  </div>
                  <div className="flex justify-center items-center pl-2 cursor-pointer">
                    <a
                      data-tooltip-id="plan-tooltip"
                      data-tooltip-content="Choose a plan that best fits your needs and start using DatalinkerAI today."
                      data-tooltip-delay-show={1000}
                    >
                      <FaInfoCircle className="text-lg" />
                    </a>
                  </div>
                  <Tooltip id="plan-tooltip" />
                </div>
              </motion.h1>
            )}
          </div>
        )}
        <div id="edit-billing-button" className="flex justify-end">
          {hasSubscription && userRole === "owner" && (
              <PermissionGuard requiredPermissions={['edit_cancel_plan']}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FaEdit size={20} />}
              onClick={manage_billing}
            >
              Edit Billing
            </Button>
                </PermissionGuard>
          )}
        </div>
      </motion.div>

      {hasSubscription ? (
        <motion.div
          className="rounded-lg shadow-xl p-6 mb-10 "
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <Typography variant="h5" gutterBottom>
            <FaRegCreditCard className="inline-block mr-2" />
            Your Subscription
          </Typography>
          <hr className="mb-4"></hr>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box id="plan-name" display="flex" justifyContent="space-between" pb={2}>
                <Typography variant="body1" fontWeight="bold">
                  Plan
                </Typography>
                <Typography variant="body1" color="primary">
                  {subscriptionInfo?.subscription_plan_name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box id="subscription-status" display="flex" justifyContent="space-between" pb={2}>
                <Typography variant="body1" fontWeight="bold">
                  Subscription Status
                </Typography>
                <Typography
                  variant="body1"
                  color={
                    subscriptionInfo?.subscription_status === "active" &&
                    !subscriptionInfo?.cancel_at_period_end
                      ? "success"
                      : subscriptionInfo?.cancel_at_period_end
                      ? "warning"
                      : "error"
                  }
                >
                  {subscriptionInfo?.cancel_at_period_end
                    ? `Cancelled, active until ${new Date(
                        subscriptionInfo?.subscription_end_date * 1000
                      ).toDateString()}`
                    : subscriptionInfo?.subscription_status.toUpperCase()}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box id="payment-status" display="flex" justifyContent="space-between" pb={2}>
                <Typography variant="body1" fontWeight="bold">
                  Payment Status
                </Typography>
                <Typography
                  variant="body1"
                  color={
                    subscriptionInfo?.payment_status_for_current_period ===
                    "paid"
                      ? "success"
                      : subscriptionInfo?.payment_status_for_current_period ===
                        "processing"
                      ? "info"
                      : "error"
                  }
                >
                  {subscriptionInfo?.payment_status_for_current_period.toUpperCase()}
                </Typography>
              </Box>
            </Grid>
            {!subscriptionInfo?.cancel_at_period_end && (
              <Grid item xs={12} sm={6}>
                <Box id="billing-date" display="flex" justifyContent="space-between" pb={2}>
                  <Typography variant="body1" fontWeight="bold">
                    Next Billing Date
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {new Date(
                      subscriptionInfo?.subscription_end_date * 1000
                    ).toDateString()}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
          <Typography variant="h5" gutterBottom>
            <FaDatabase className="inline-block mr-2" />
            Resource Usage
          </Typography>
          <hr className="mb-4"></hr>
          <Grid id="resource-table" container spacing={3}>
            {[
              {
                title: "GPT-3.5 Turbo Input Tokens",
                available: balanceInfo?.gpt_3_5_turbo_input_tokens_available,
                limit: balanceInfo?.gpt_3_5_turbo_input_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "GPT-3.5 Turbo Output Tokens",
                available: balanceInfo?.gpt_3_5_turbo_output_tokens_available,
                limit: balanceInfo?.gpt_3_5_turbo_output_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "GPT-4 Input Tokens",
                available: balanceInfo?.gpt_4_input_tokens_available,
                limit: balanceInfo?.gpt_4_input_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "GPT-4 Output Tokens",
                available: balanceInfo?.gpt_4_output_tokens_available,
                limit: balanceInfo?.gpt_4_output_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "GPT-4 Turbo Input Tokens",
                available: balanceInfo?.gpt_4_turbo_input_tokens_available,
                limit: balanceInfo?.gpt_4_turbo_input_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "GPT-4 Turbo Output Tokens",
                available: balanceInfo?.gpt_4_turbo_output_tokens_available,
                limit: balanceInfo?.gpt_4_turbo_output_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              // Gpt-4o
              {
                title: "GPT-4o Input Tokens",
                available: balanceInfo?.gpt_4o_input_tokens_available,
                limit: balanceInfo?.gpt_4o_input_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "GPT-4o Output Tokens",
                available: balanceInfo?.gpt_4o_output_tokens_available,
                limit: balanceInfo?.gpt_4o_output_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              // Gemini-1.5-Pro
              {
                title: "Gemini-1.5-Pro Input Tokens",
                available: balanceInfo?.gemini_1_5_pro_input_tokens_available,
                limit: balanceInfo?.gemini_1_5_pro_input_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "Gemini-1.5-Pro Output Tokens",
                available: balanceInfo?.gemini_1_5_pro_output_tokens_available,
                limit: balanceInfo?.gemini_1_5_pro_output_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              // Gemini-1.5-Flash
              {
                title: "Gemini-1.5-Flash Input Tokens",
                available: balanceInfo?.gemini_1_5_flash_input_tokens_available,
                limit: balanceInfo?.gemini_1_5_flash_input_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "Gemini-1.5-Flash Output Tokens",
                available:
                  balanceInfo?.gemini_1_5_flash_output_tokens_available,
                limit: balanceInfo?.gemini_1_5_flash_output_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              // Claude Opus
              {
                title: "Claude-Opus Input Tokens",
                available: balanceInfo?.claude_opus_input_tokens_available,
                limit: balanceInfo?.claude_opus_input_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "Claude-Opus Output Tokens",
                available: balanceInfo?.claude_opus_output_tokens_available,
                limit: balanceInfo?.claude_opus_output_tokens_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "Text to Speech (Characters)",
                available: balanceInfo?.tts_1_available,
                limit: balanceInfo?.tts_1_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "Speech to Text - (Seconds)",
                available: balanceInfo?.whisper_1_available,
                limit: balanceInfo?.whisper_1_limit,
                gradient: "from-white-100 to-white-100",
              },
              {
                title: "Storage (MB)",
                available: (balanceInfo?.storage_available ?? 0).toFixed(2),
                limit: balanceInfo?.storage_limit,
                gradient: "from-gray-300 to-gray-300",
              },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  id="llm-box"
                  elevation={3}
                  sx={{
                    padding: 2,
                    background: `linear-gradient(to right, ${item.gradient})`,
                    transition: "transform 0.2s",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                    {item.title}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                      <CustomProgressBar
                        now={item.available}
                        max={item.limit}
                      />
                    </Box>
                    {item.title !== "Storage (MB)" && (
                        <PermissionGuard requiredPermissions={['edit_purchase_tokens']}>
                      <Button
                        id="token-add-button"
                        variant="contained"
                        color="secondary"
                        startIcon={<FaPlus />}
                        onClick={() => loadMoreResource(item)}
                        sx={{ marginLeft: 2 }}
                      >
                        Add
                      </Button>
                        </PermissionGuard>
                    )}
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      ) : loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="rounded-lg shadow-xl overflow-auto mb-52" sx={{ maxHeight: "100dvh" }}>
        {userRole !== "owner" ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <FaExclamationTriangle size={200} />
            <Typography variant="h6" className="m-24">
              Only the organisation owner can subscribe to a plan. Please
              contact the organisation owner.
            </Typography>
          </Box>
        ) : (
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_STRIPE_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_KEY}
            customer-session-client-secret={customerSession}
            client-reference-id={state.selectedOrganisation._id}
          ></stripe-pricing-table>
        )}
      </Box>
    )}
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: '70%',
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          {modalTitle}
        </Typography>
        <Box id="modal-description" sx={{ mt: 2 }}>
          {modalContent}
        </Box>
      </Box>
    </Modal>
  </motion.div>
);
};

export default BillingComponent;

